import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    circular: {
      position: 'relative',
      display: 'inline-block',
    },
    linear: {
      width: '100%',
    },
  }),
);

export function RefreshSpinner(props) {
  const { linear, size, secondary, thickness } = props;

  const classes = useStyles(props);

  const color = secondary ? 'secondary' : 'primary';

  if (linear) {
    return <LinearProgress color={color} classes={{ root: classNames(classes.root, classes.linear) }} />;
  }
  return (
    <CircularProgress
      size={size}
      // The value of the progress indicator for the determinate and static variants. Value between 0 and 100.
      thickness={thickness}
      color={color}
      left={10}
      top={10}
      classes={{ root: classNames(classes.root, classes.circular) }}
    />
  );
}

RefreshSpinner.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  thickness: PropTypes.number,
  secondary: PropTypes.bool,
  linear: PropTypes.bool,
  classes: PropTypes.object,
};

RefreshSpinner.defaultProps = {
  size: 64,
  thickness: 4,
  secondary: false,
  linear: false,
  classes: {},
};
