import moment from 'moment';

export const datesDiffInDays = (startDate: string | Date, endDate: string | Date): number =>
  Math.ceil(moment.utc(endDate).diff(moment.utc(startDate), 'days', true));

export const datesDiffInHours = (startDate: string | Date, endDate: string | Date): number =>
  Math.round(moment.utc(endDate).diff(moment.utc(startDate), 'hour', true));

export const datesDiffInMinutes = (startDate: string | Date, endDate: string | Date): number =>
  Math.round(moment.utc(endDate).diff(moment.utc(startDate), 'minute', true));
