import isPlainObject from 'lodash/isPlainObject';

/**
 * this function iterates through object properties
 * and deletes any keys with null values
 *
 * @param obj
 * @returns {*}
 * @private
 */
export const _deleteNullValuesFromObject = (obj) => {
  // eslint-disable-next-line consistent-return
  Object.entries(obj).forEach(([key, value]) => {
    if (value === null) {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    } else if (isPlainObject(value)) {
      return _deleteNullValuesFromObject(obj[key]);
    }
  });
};

export const formatDate = (date, withTime = true) => {
  if (!date) {
    return 'N/A';
  }
  // Prettify the time string
  if (withTime) {
    return date
      .replace('T', ' ')
      .replace(/\.[0-9]{3,3}/, '')
      .replace('Z', '');
  }
  // Without time string
  return date.split('T')[0];
};

/**
 *
 * @param x - to parse
 * @param bu - back up number
 * @returns {number}
 */
export const toFloat = (x, bu = 0) => {
  const float = parseFloat(parseFloat(x).toFixed(3));
  if (!float && float !== 0) {
    return bu;
  } else {
    return float;
  }
};

export function getCrackleUrl() {
  if (window.location.origin.includes('rocks')) {
    return 'http://app.selectom.rocks:3000';
  }
  if (window.location.origin.includes('wtf')) {
    return 'https://app.selectom.wtf';
  }
  if (window.location.origin.includes('to')) {
    return 'https://app.selectom.to';
  }
  return 'https://app.mayple.com';
}

export function getDingUrl() {
  if (window.location.origin.includes('selectom.rocks')) {
    return 'http://welcome.selectom.rocks:3100';
  }
  if (window.location.origin.includes('selectom.to')) {
    return 'https://welcome.selectom.to';
  }
  if (window.location.origin.includes('selectom.wtf')) {
    return 'https://welcome.selectom.wtf';
  }
  return 'https://welcome.mayple.com';
}
