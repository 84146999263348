/* Industries */
import { DEPRECATED_INDUSTRIES_SUBCATEGORIES, INDUSTRIES_CATEGORIES_LABELS, INDUSTRIES_SUBCATEGORIES } from './consts';

import {
  createCategorizedEnum,
  createFlatCategoriesEnum,
  createGroupedEnum,
  groupedPairsToLabelLookup,
} from './helper';

const industryCategoryKey = 'industry';

export const INDUSTRIES_GROUPS = createGroupedEnum(
  INDUSTRIES_CATEGORIES_LABELS,
  INDUSTRIES_SUBCATEGORIES,
  DEPRECATED_INDUSTRIES_SUBCATEGORIES,
  industryCategoryKey,
);

export const INDUSTRIES_PAIRS = createCategorizedEnum(
  INDUSTRIES_CATEGORIES_LABELS,
  INDUSTRIES_SUBCATEGORIES,
  DEPRECATED_INDUSTRIES_SUBCATEGORIES,
  industryCategoryKey,
);

export const INDUSTRIES_PAIRS_FLAT = createFlatCategoriesEnum(
  INDUSTRIES_CATEGORIES_LABELS,
  INDUSTRIES_SUBCATEGORIES,
  DEPRECATED_INDUSTRIES_SUBCATEGORIES,
  industryCategoryKey,
);

export const INDUSTRIES_LABELS_LOOKUP = groupedPairsToLabelLookup(INDUSTRIES_GROUPS, INDUSTRIES_CATEGORIES_LABELS);
