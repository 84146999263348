import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';

export function TextIcon(props) {
  // console.log('TextIcon props', props);

  const { icon, color, size, secondary, alternate, style, inherit, className } = props;

  const finalStyle = { verticalAlign: 'middle', ...style };

  let finalColor = 'primary';
  if (color) {
    finalStyle.color = color;
  } else if (secondary) {
    finalColor = 'secondary';
  } else if (inherit) {
    finalColor = 'inherit';
  } else if (alternate) {
    finalColor = 'secondary';
  } else {
    finalColor = 'primary';
  }

  if (size) {
    finalStyle.fontSize = size;
  }

  if (icon.startsWith('fa-')) {
    return <i className={classNames('fa', icon, className)} color={finalColor} style={finalStyle} />;
  } else {
    return (
      <Icon className={classNames(className)} color={finalColor} style={finalStyle}>
        {icon}
      </Icon>
    );
  }
}

TextIcon.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  alternate: PropTypes.bool,
  inherit: PropTypes.bool,
  secondary: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types,react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
};

TextIcon.defaultProps = {
  icon: '',
  color: '',
  size: null,
  style: {},
  alternate: false,
  secondary: false,
  inherit: true,
  className: '',
};
