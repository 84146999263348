import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import MuiDialogActions from '@material-ui/core/DialogActions';

import { DialogActionsProps } from '../../types';
import Button from '../../../../inputs/Button';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

const DialogActions: React.FC<DialogActionsProps> = (props) => {
  const classes = useStyles(props);

  const { actions } = props;

  if (!actions) {
    return null;
  }

  return (
    <MuiDialogActions classes={{ root: classes.root }}>
      {actions.map((buttonProps, index) => (
        <Button key={`dialog-action-${index}`} {...buttonProps} />
      ))}
    </MuiDialogActions>
  );
};

export default DialogActions;
