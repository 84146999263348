import React, { FC, useCallback, useEffect, useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import useStyles from './style';

interface GlobalEntitySearchProps {
  onSearch?: (searchTerm: string) => void;
  initialSearchValue?: string;
}

const GlobalEntitySearch: FC<GlobalEntitySearchProps> = (props) => {
  const classes = useStyles();
  const { initialSearchValue, onSearch } = props;

  const [searchTerm, setSearchTerm] = useState<string | undefined>(initialSearchValue || '');

  useEffect(() => {
    setSearchTerm((prevState): string => {
      if (!prevState && initialSearchValue !== '') {
        return initialSearchValue || '';
      }
      return '';
    });
  }, [initialSearchValue]);

  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSearchTerm(value);
    },
    [setSearchTerm],
  );

  const keyDownSearchHandler = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' && searchTerm && searchTerm !== '') {
        onSearch?.(searchTerm);
      }
    },
    [searchTerm, onSearch],
  );

  const onClickSearchHandler = useCallback(() => {
    if (searchTerm && searchTerm !== '') {
      onSearch?.(searchTerm);
    }
  }, [searchTerm, onSearch]);

  return (
    <div className={classes.root}>
      <div className={classes.searchIconContainer}>
        <IconButton size="small" onClick={onClickSearchHandler}>
          <SearchIcon className={classes.searchIcon} />
        </IconButton>
      </div>
      <InputBase
        placeholder="Search…"
        inputProps={{ 'aria-label': 'search', maxLength: 100 }}
        onChange={onChangeHandler}
        onKeyPress={keyDownSearchHandler}
        value={searchTerm}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
      />
    </div>
  );
};

export default GlobalEntitySearch;
