import { AutoSuggestSkills } from './types';

export const STATIC_ASSETS_CDN_URL = 'https://static.cdn.mayple.com';

export const SHARED_FILE_MIME_TYPE = {
  TEXT: 'text/plain',
  PDF: 'application/pdf',
  MS_WORD: 'application/msword',
  MS_POWER_POINT: 'application/vnd.ms-powerpoint',
  MS_VISIO: 'application/vnd.visio',
  MS_EXCEL: 'application/vnd.ms-excel',
  ICON: 'image/vnd.microsoft.icon',
  CALENDAR: 'text/calendar',
  JPEG: 'image/jpeg',
  JSON: 'application/json',
  MP3: 'audio/mpeg',
  MPEG: 'audio/mpeg',
  RAR: 'application/x-rar-compressed',
  ZIP: 'application/zip',
  SEVEN_ZIP: 'application/x-7z-compressed',
  XML: 'text/xml',
  GOOGLE_SLIDES: 'application/vnd.google-apps.presentation',
};

export const BRIEF_CATEGORY_ESSENTIAL = 'essential';

// Cookies constants
export const APP_COOKIES = {
  //
  WEBSITE_PRE_FILLED_VALUES: '_mayple_pre_filled',
  //
  TEST_COOKIE: '_mayple_test_c',
  //
  LEAD_TOKEN_COOKIE: '_mayple_d_plt',
  // Mayple ding project create cookie
  PROJECT_CREATE: '_mayple_d_pc',
  // Mayple ding project create local cookie
  PROJECT_CREATE_LOCAL: '_mayple_d_pcl',
  // Mayple ding project mock local cookie
  PROJECT_MOCK_COOKIE: '_mayple_d_pml',
  // hasOffersTransactionId cookie, set via the has offers link
  HAS_OFFERS_ID: '_mayple_utm_h_o_id',
  // lead event reported
  LEAD_REPORTED: '_mayple_lead',
  // Unkapped cookie
  UNKAPPED_UKID: '_uk_ukid',
  // funnel a/b test version
  FUNNEL_VERSION: '_mayple_funnel_version',
  // Is Qualified Lead (Qualification score >= 0)
  IS_QUALIFIED_LEAD: '_mayple_q_lead',
};

export const URL_PARAMS = {
  MAYPLE_UNIVERSAL_TRACKING_ID: 'mut_id',
  PROJECT_LEAD_CREATE_TOKEN: 'token',
  EMAIL: 'email',
};

export const MAYPLE_ANALYTICS_TIME_TO_WAIT = 3;

export const HUBSPOT_CONTACT_LIFE_CYCLE = {
  // Contact with basic information that did not show high intent
  SUBSCRIBER: 'subscriber',
  // Contact with basic information that did show high intent of using mayple
  LEAD: 'lead',
  // Contact that finished basic brief, but did not sign up
  MQL: 'marketingqualifiedlead',
  // Contact that signed up
  SQL: 'salesqualifiedlead',
  // Contact received package for a project
  OPPORTUNITY: 'opportunity',
  // Contact accepted package after payment
  CUSTOMER: 'customer',
  // Customer that recommends mayple services to others
  EVANGELIST: 'evangelist',
  //
  OTHER: 'other',
};

export const FILE_STACK_DEFAULT_SOURCES = [
  'local_file_system',
  'facebook',
  'googledrive',
  'imagesearch',
  'instagram',
  'box',
  'github',
  'gmail',
  'picasa',
  'onedrive',
  'webcam',
];

export const AUTO_SUGGEST_SKILLS = {
  [AutoSuggestSkills.AUTO]: 'AUTO',
  [AutoSuggestSkills.MANUAL]: 'MANUAL',
};

export const REMOTE_WORK = {
  DOMESTIC: 'DOMESTIC',
  ON_TARGET: 'ON_TARGET',
  NO_PREFERENCE: 'NO_PREFERENCE',
  NA: 'N/A',
};

/**
 * Enum representing possible answer types
 * @type {{ENUM: string, STRING: string, CURRENCY_RANGE: string}}
 */
export const ANSWER_TYPES = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  NUMERIC_RANGE: 'NUMERIC_RANGE',
  CURRENCY: 'CURRENCY',
  CURRENCY_RANGE: 'CURRENCY_RANGE',
  ENUM: 'ENUM',
  LOCATION_NAME: 'LOCATION_NAME',
  URL: 'URL',
  EMAIL: 'EMAIL',
  PHONE_NUMBER: 'PHONE_NUMBER',
  FILE: 'FILE',
  IMAGE: 'IMAGE',
  DATE: 'DATE',
};

// PAYMENT_METHODS
export const PAYMENT_METHODS = {
  CREDIT_CARD: 'CREDIT_CARD',
  PAYPAL: 'PAYPAL',
};

export const TRANSLATION_NS = 'translation';

export const PPC_SERVICE_TYPE = 'PAID_MEDIA';
