// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactTable input,
.ReactTable select {
  display: inline-block;
  height: auto;
  width: 100%;
}

.ReactTable .rt-th select {
  height: 100%;
  width: 100%;
}

.ReactTable .rt-thead {
  /*background: lightgray;*/
}
`, "",{"version":3,"sources":["webpack://./src/fe_common/client/app/rt-override.css"],"names":[],"mappings":"AAAA;;EAEE,qBAAqB;EACrB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[":global(.ReactTable input),\n:global(.ReactTable select) {\n  display: inline-block;\n  height: auto;\n  width: 100%;\n}\n\n:global(.ReactTable .rt-th select) {\n  height: 100%;\n  width: 100%;\n}\n\n:global(.ReactTable .rt-thead) {\n  /*background: lightgray;*/\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
