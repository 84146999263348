type Links = {
  label: string;
  href: string;
};

const links: Links[] = [
  { label: 'Dashboard', href: '/' },
  { label: 'Marketers', href: '/marketers' },
  { label: 'Companies', href: '/companies' },
  { label: 'Projects', href: '/projects' },
  { label: 'Internal Accounts', href: '/internal-accounts' },
  { label: 'Users', href: '/users' },
  { label: 'Project Leads', href: '/project_leads' },
  { label: 'Matching Simulator', href: '/projects/simulator' },
  { label: 'Projects Billing Review', href: '/billings' },
  { label: 'Mailchimp Pay-in Report', href: '/reports/pay-in' },
  /* { label: 'Administration Status Report', href: '/billings/overview' }, */
  { label: 'Match Statistics Report', href: '/reports/matching' },
  { label: 'Insights Feed Report', href: '/reports/insights' },
];

export default links;
