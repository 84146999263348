import { useState } from 'react';

export interface DialogState {
  open: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}

const useDialog = (isOpen = false): DialogState => {
  const [open, setOpen] = useState(isOpen);

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  return {
    open,
    openDialog,
    closeDialog,
  };
};

export default useDialog;
