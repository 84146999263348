import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { LabelWithIconProps } from './index';

import { colorPalette } from '../../../app/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    listItem: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
    },
    listItemAvatar: {
      minWidth: 'unset',
      lineHeight: '14px',
      paddingRight: '0.5rem',
      '& .MuiSvgIcon-root': {
        color: (props: LabelWithIconProps) => props.color || colorPalette.gray['60'],
      },
    },
    listItemAvatarIcon: {
      [theme.breakpoints.down('md')]: {
        lineHeight: '0.875rem',
        fontSize: '0.75rem',
      },
    },
    listItemText: {
      color: (props: LabelWithIconProps) => props.color || colorPalette.gray['60'],
      [theme.breakpoints.down('md')]: {
        '& .MuiTypography-body2': {
          lineHeight: '0.875rem',
          fontSize: '0.75rem',
        },
      },
    },
  }),
);

export default useStyles;
