import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { CalloutMessageProps } from './types';
import { Button } from '../../inputs';

import useStyles from './styles';

const CalloutMessage: FC<CalloutMessageProps> = (props) => {
  const {
    message,
    actionDisabled = false,
    actionLabel = '',
    title = '',
    type = 'info',
    onClick = null,
    icon = null,
    classes: classesFromProps,
    actionProps = {},
    alternativeActionButton,
  } = props;

  const styles = Object.values(classesFromProps || {});

  const classes = useStyles(props);

  // Callout message style contains definitions for both Alert and AlertTitle classes,
  // so we split those (to prevent console warning)
  const alertClasses = {
    root: classes.root,
    icon: classes.icon,
    message: classes.message,
    action: classes.action,
  };

  const alertTitleClasses = { root: classes.title };

  const actionButton: React.ReactNode = useMemo(() => {
    if (alternativeActionButton) {
      return alternativeActionButton;
    }
    if (onClick) {
      return (
        <Button
          variant="outlined"
          label={actionLabel}
          onClick={onClick}
          color="primary"
          disabled={actionDisabled}
          className={classes.action}
          {...actionProps}
        />
      );
    }
    return null;
  }, [actionDisabled, actionLabel, actionProps, alternativeActionButton, classes.action, onClick]);

  return (
    <Alert severity={type} classes={alertClasses} className={classNames(...styles)} icon={icon} action={actionButton}>
      {!!title && <AlertTitle classes={alertTitleClasses}>{title}</AlertTitle>}
      {message}
    </Alert>
  );
};

export default CalloutMessage;
