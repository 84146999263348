import { useEffect } from 'react';

import { reportEvent } from '../logic/events';

const useReportPageView = (pageName: string, tabName?: string): void => {
  useEffect(() => {
    const traits: Record<string, string> = {
      category: 'Page',
      action: 'Opened',
      pageName,
    };

    if (tabName) {
      traits.tabName = tabName;
    }
    const label = tabName ? `${pageName}.${tabName}` : pageName;

    reportEvent('Page', 'Opened', label, traits);

    // We only want to run this 1 time at page load.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useReportPageView;
