import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Link, { LinkProps } from '@material-ui/core/Link';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

interface ExternalLinkProps extends LinkProps {
  href: string;
  onClick?: React.MouseEventHandler;
  classes?: Record<string, string>;
}

const ExternalLink: React.FC<ExternalLinkProps> = (props) => {
  const classes = useStyles(props);

  const { href, onClick, children, ...rest } = props;

  return (
    <Link className={classes.root} href={href} onClick={onClick} target="_blank" rel="noreferrer noopener" {...rest}>
      {children}
    </Link>
  );
};

export default ExternalLink;
