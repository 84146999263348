/* eslint-disable no-underscore-dangle */
import { ApolloClient } from 'apollo-client';
import { createHttpLink, HttpLink } from 'apollo-link-http';
import {
  defaultDataIdFromObject,
  InMemoryCache,
  IntrospectionFragmentMatcher,
  NormalizedCacheObject,
} from 'apollo-cache-inmemory';
import { IdGetterObj } from 'apollo-cache-inmemory/lib/types';
import { setContext } from 'apollo-link-context';
import introspectionQueryResultData from '@mayple/fragment-types';

import { config } from '../config';

const growlHeader: Record<string, string> = {};

interface IdGetterObjExtended extends IdGetterObj {
  temporaryCacheKey?: string;
  uuid?: string;
  answerSetUuid?: string;
}

// Client-side Apollo client
function createBrowserClient() {
  const httpLink = createHttpLink({
    uri: config.graphqlUrl,
    credentials: 'include', // Important for CSRF
    batchInterval: 10,
  } as HttpLink.Options);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const middlewareLink = setContext((context) => {
    growlHeader['x-cpanel-referer'] = window?.location?.href || 'N/A';

    return {
      headers: growlHeader,
    };
  });

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  // use with apollo-client
  const link = middlewareLink.concat(httpLink);
  // cache for re-using queries with same variables
  const cache = new InMemoryCache({
    // look at https://www.apollographql.com/docs/react/v2.6/caching/cache-configuration/#custom-identifiers
    dataIdFromObject: (obj: IdGetterObjExtended) => {
      switch (obj?.__typename) {
        case 'AdditionalService':
          return obj?.temporaryCacheKey || defaultDataIdFromObject(obj);
        case 'ProjectCycleSnapshot':
          return defaultDataIdFromObject(obj);
        default:
          return obj?.uuid || defaultDataIdFromObject(obj);
      }
    }, // custom idGetter, for caching based on unique uuid
    // For now, leave true, false causing errors. look at https://github.com/apollographql/apollo-client/issues/3219
    addTypename: true,
    cacheRedirects: {},
    fragmentMatcher,
    // when we upgrade to Apollo v.3 client
    // look at : https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields
    // typePolicies:     {
    //   AdditionalService: {
    //     keyFields: ['temporaryCacheKey'],
    //   },
    // },
  });
  // Create the apollo client
  return new ApolloClient({
    link,
    cache: cache.restore(window.__APOLLO_CLIENT__),
    connectToDevTools: true,
    queryDeduplication: true,
  });
}

let clientInstance: ApolloClient<NormalizedCacheObject> | null = null;

const getApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  if (clientInstance) {
    return clientInstance;
  }

  clientInstance = createBrowserClient();

  return clientInstance;
};

export default getApolloClient;
