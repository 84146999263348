import React from 'react';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WarningIcon from '@material-ui/icons/Warning';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import PanToolIcon from '@material-ui/icons/PanTool';
import EditIcon from '@material-ui/icons/Edit';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HelpIcon from '@material-ui/icons/Help';
import FilterListIcon from '@material-ui/icons/FilterList';

/*
 * Can see the full list of MUI icons here:
 * https://material-ui.com/components/material-icons/
 * */
export const muiIcons = {
  add: <AddIcon />,
  arrowLeft: <ChevronLeft />,
  arrowRight: <ChevronRight />,
  attachFile: <AttachFileIcon />,
  atm: <LocalAtmIcon />,
  bank: <AccountBalanceIcon />,
  copyFile: <FileCopyIcon />,
  deleteForever: <DeleteForeverIcon />,
  download: <CloudDownloadIcon />,
  edit: <EditIcon />,
  eye: <VisibilityIcon />,
  filter: <FilterListIcon />,
  giftCard: <CardGiftcardIcon />,
  hand: <PanToolIcon />,
  help: <HelpIcon />,
  highlightOff: <HighlightOffIcon />,
  openInNew: <OpenInNewIcon />,
  warning: <WarningIcon />,
};

export const iconCodes = {
  // Page Themes
  menu: 'menu',
  home: 'home',
  signOut: 'keyboard_tab',
  media: 'perm_media',
  dashboard: 'dashboard',
  statistics: 'timeline',
  location: 'location_on',
  spinner: 'fa-spinner',
  cog: 'fa-cog',
  information: 'fa-info-circle',
  infoNoCircle: 'fa-info',
  questionMark: 'fa-question-circle',
  messages: 'messages',
  notifications: 'notifications',
  settings: 'settings',
  profile: 'person_outline',
  user: 'fa-user',
  users: 'people',
  users_outlined: 'people_outlined',
  account: 'account_circle',
  fundAccount: 'attach_money',
  task: 'assignment_turned_in',
  assignment: 'assignment',
  certificate: 'card_membership',
  globe: 'language',
  experience: 'school',
  map: 'map',
  channels: 'device_hub',
  compass: 'fa-compass',
  target: 'my_location',
  arrowForward: 'chevron_right',
  arrowBack: 'chevron_left',
  forbidden: 'fa-times',
  development: 'developer_mode',
  wrench: 'build',
  money_circle: 'monetization_on',
  renewal: 'autorenew',
  add_person: 'person_add',
  supervisor: 'supervisor_account',
  list: 'view_list',
  brush: 'brush',
  find_replace: 'find_replace',
  video: 'videocam',
  copyWriting: 'text_format',
  fingerprint: 'fingerprint',
  error: 'error',
  error_outline: 'error_outline',
  help: 'help_outline',
  file: 'insert_drive_file',
  flag: 'flag',
  watch_later: 'watch_later',
  store: 'store',
  briefcase: 'work_outline',
  quote: 'format_quote',
  compareArrows: 'compare_arrows',
  cardTravel: 'card_travel',

  // Actions
  search: 'search',
  attachFiles: 'attach_file',
  attachFilesSide: 'attachment',
  submitForm: 'check',
  resetForm: 'clear',
  close: 'close',
  delete: 'delete',
  accept: 'check',
  accept_outline: 'check_circle',
  reject: 'cancel',
  cancel: 'clear',
  start: 'play_arrow',
  stop: 'fa-stop',
  schedule: 'clock',
  view: 'remove_red_eye',
  goBack: 'arrow_back',
  goForward: 'arrow_forward',
  invite: 'add_box',
  create: 'create',
  request: 'pan_tool',
  decline: 'not_interested',
  wait: 'hourglass_empty',
  access: 'launch',
  sendMessage: 'chat',
  copy: 'content_copy',
  upload: 'file_upload',
  cloudUpload: 'cloud_upload',
  generate: 'fa-random',
  approve: 'verified_user',
  unapprove: 'block',
  recommend: 'fa-magic',
  refresh: 'refresh',
  remove: 'remove_circle',
  gift: 'fa-gift',
  ungift: 'fa-eraser',
  lock: 'fa-lock',
  timer: 'timer',
  clock: 'access_time',
  star: 'star_rate',
  link: 'link',
  share: 'share',
  send: 'next_week',
  exchange: 'swap_horiz',
  phone: 'phone',
  email: 'email',
  add: 'add',
  add_circle: 'add_circle',
  save: 'save',
  like: 'thumb_up',
  download: 'get_app',
  edit: 'edit',
  group: 'group',
  donutSmall: 'donut_small',
  spellCheck: 'spellcheck',
  trophy: 'fa-trophy',
  openInNew: 'open_in_new',

  // Entities:
  marketer: 'person_pin',
  marketers: 'person_pin',
  company: 'card_travel',
  companies: 'card_travel',
  campaign: 'fa-bullhorn',
  campaigns: 'fa-bullhorn',
  project: 'fa-bullhorn',
  projects: 'fa-bullhorn',

  google: 'fa-google',
  facebook: 'fa-facebook-square',
  linkedin: 'fa-linkedin-square',
  paypal: 'fa-paypal',
  website: 'fa-globe',
  mock: 'bug_report',
  businessManager: 'fa-suitcase',

  payment: 'payment',

  language: 'fa-language',
  industry: 'fa-industry',

  cash: 'fa-money',
  wireTransfer: 'fa-university',

  spend: 'fa-money',
  impressions: 'fa-eye',
  clicks: 'fa-mouse-pointer',
  leads: 'fa-address-book',
  conversions: 'fa-handshake-o',

  // expressions
  sadFace: 'fa-frown-o',
  happyFace: 'fa-smile-o',
  neutralFace: 'fa-meh-o',
  satisfied: 'sentiment_very_satisfied',
};
