import { toast } from 'react-toastify';

// https://github.com/fkhadra/react-toastify for all options
export function setNotification(message = 'Input message please', type = 'info', options = {}) {
  if (['default', 'success', 'info', 'warning', 'error'].includes(type)) {
    toast(message, {
      ...options,
      type,
    });
  }
}

export function setEntityOperationResponseNotification(response) {
  if (!response) {
    setNotification('Error! No response from server', 'error');
  }

  const { success, result } = response;

  setNotification(result, success ? 'success' : 'error');
}
