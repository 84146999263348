import { STATIC_ASSETS_CDN_URL } from './consts';

const svgLink = `${STATIC_ASSETS_CDN_URL}/svgs/`; // CDN host
const marketingServicesFolder = `${svgLink}marketing-services/`;
const brandsFolder = `${svgLink}brands/`;
const mainGoalFolder = `${svgLink}mainGoal/`;
const targetKPIFolder = `${svgLink}targetKPI/`;
const launchTimeFramePreferenceFolder = `${svgLink}launchTimeFramePreference/`;
const marketerMatchFolder = `${svgLink}marketerMatch/`;
const opportunitiesFolder = `${svgLink}opportunities/`;
const marketingPlanFolder = `${svgLink}uploadMarketingPlan/`;
const streamlineIconsFolder = `${svgLink}streamlineIcons/`;

// eslint-disable-next-line import/prefer-default-export
export const svgs: Record<string, string> = {
  // Marketing services
  guarantee: `${marketingServicesFolder}guarantee.svg`,
  afford: `${marketingServicesFolder}afford.svg`,
  refresh: `${marketingServicesFolder}refresh.svg`,
  taskListSync: `${marketingServicesFolder}task-list-sync.svg`,
  brandingLight: `${marketingServicesFolder}brandingLight.svg`,
  brandingRegular: `${marketingServicesFolder}brandingRegular.svg`,
  affiliateMarketingLight: `${marketingServicesFolder}affiliateMarketingLight.svg`,
  affiliateMarketingRegular: `${marketingServicesFolder}affiliateMarketingRegular.svg`,
  searchEngineOptimizationLight: `${marketingServicesFolder}seoLight.svg`,
  searchEngineOptimizationRegular: `${marketingServicesFolder}seoRegular.svg`,
  cmoLight: `${marketingServicesFolder}cmoLight.svg`,
  cmoRegular: `${marketingServicesFolder}cmoRegular.svg`,
  appStoreOptimizationLight: `${marketingServicesFolder}appStoreOptimizationLight.svg`,
  appStoreOptimizationRegular: `${marketingServicesFolder}appStoreOptimizationRegular.svg`,
  copywritingLight: `${marketingServicesFolder}copywritingLight.svg`,
  copywritingRegular: `${marketingServicesFolder}copywritingRegular.svg`,
  videoLight: `${marketingServicesFolder}videoLight.svg`,
  videoRegular: `${marketingServicesFolder}videoRegular.svg`,
  smmLight: `${marketingServicesFolder}smmLight.svg`,
  smmRegular: `${marketingServicesFolder}smmRegular.svg`,
  graphicDesignLight: `${marketingServicesFolder}graphicDesignLight.svg`,
  graphicDesignRegular: `${marketingServicesFolder}graphicDesignRegular.svg`,
  mediaManagementLight: `${marketingServicesFolder}mediaManagementLight.svg`,
  mediaManagementRegular: `${marketingServicesFolder}mediaManagementRegular.svg`,
  marketingAutomationLight: `${marketingServicesFolder}marketingAutomationLight.svg`,
  marketingAutomationRegular: `${marketingServicesFolder}marketingAutomationRegular.svg`,
  chatbotMarketingLight: `${marketingServicesFolder}chatbotMarketingLight.svg`,
  chatbotMarketingRegular: `${marketingServicesFolder}chatbotMarketingRegular.svg`,
  communityManagementLight: `${marketingServicesFolder}communityManagementLight.svg`,
  communityManagementRegular: `${marketingServicesFolder}communityManagementRegular.svg`,
  contentLight: `${marketingServicesFolder}contentLight.svg`,
  contentRegular: `${marketingServicesFolder}contentRegular.svg`,
  crmImplementationLight: `${marketingServicesFolder}crmImplementationLight.svg`,
  crmImplementationRegular: `${marketingServicesFolder}crmImplementationRegular.svg`,
  croLight: `${marketingServicesFolder}croLight.svg`,
  croRegular: `${marketingServicesFolder}croRegular.svg`,
  dspLight: `${marketingServicesFolder}dspLight.svg`,
  dspRegular: `${marketingServicesFolder}dspRegular.svg`,
  emailMarketingLight: `${marketingServicesFolder}emailMarketingLight.svg`,
  emailMarketingRegular: `${marketingServicesFolder}emailMarketingRegular.svg`,
  influencersMarketingLight: `${marketingServicesFolder}influencersMarketingLight.svg`,
  influencersMarketingRegular: `${marketingServicesFolder}influencersMarketingRegular.svg`,
  marketingStrategyLight: `${marketingServicesFolder}marketingStrategyLight.svg`,
  marketingStrategyRegular: `${marketingServicesFolder}marketingStrategyRegular.svg`,
  mediaSetupLight: `${marketingServicesFolder}mediaSetupLight.svg`,
  mediaSetupRegular: `${marketingServicesFolder}mediaSetupRegular.svg`,
  socialMediaManagementLight: `${marketingServicesFolder}socialMediaManagementLight.svg`,
  socialMediaManagementRegular: `${marketingServicesFolder}socialMediaManagementRegular.svg`,
  trackingImplementationLight: `${marketingServicesFolder}trackingImplementationLight.svg`,
  trackingImplementationRegular: `${marketingServicesFolder}trackingImplementationRegular.svg`,
  webDesignLight: `${marketingServicesFolder}webDesignLight.svg`,
  webDesignRegular: `${marketingServicesFolder}webDesignRegular.svg`,
  webDevelopmentLight: `${marketingServicesFolder}webDevelopmentLight.svg`,
  webDevelopmentRegular: `${marketingServicesFolder}webDevelopmentRegular.svg`,
  websiteOptimizationLight: `${marketingServicesFolder}websiteOptimizationLight.svg`,
  websiteOptimizationRegular: `${marketingServicesFolder}websiteOptimizationRegular.svg`,
  publicRelationsLight: `${marketingServicesFolder}publicRelationsLight.svg`,
  publicRelationsRegular: `${marketingServicesFolder}publicRelationsRegular.svg`,
  growthHackingLight: `${marketingServicesFolder}growthHackingLight.svg`,
  growthHackingRegular: `${marketingServicesFolder}growthHackingRegular.svg`,
  dataOnboardingRegular: `${marketingServicesFolder}dataOnboardingRegular.svg`,
  dataOnboardingLight: `${marketingServicesFolder}dataOnboardingLight.svg`,
  prepaidMediaLight: `${marketingServicesFolder}prepaidMediaLight.svg`,
  prepaidMediaRegular: `${marketingServicesFolder}prepaidMediaRegular.svg`,
  otherMediaLight: `${marketingServicesFolder}otherMediaLight.svg`,
  otherMediaRegular: `${marketingServicesFolder}otherMediaRegular.svg`,
  briefSecondary: `${marketingServicesFolder}briefSecondary.svg`,
  thumbsUpFlag: `${marketingServicesFolder}thumbsUpFlag.svg`,
  //  project targetKPI
  roasLight: `${targetKPIFolder}roasLight.svg`,
  roasRegular: `${targetKPIFolder}roasRegular.svg`,
  registrationsLight: `${targetKPIFolder}registrationsLight.svg`,
  registrationsRegular: `${targetKPIFolder}registrationsRegular.svg`,
  impressionsLight: `${targetKPIFolder}impressionsLight.svg`,
  impressionsRegular: `${targetKPIFolder}impressionsRegular.svg`,
  purchasesLight: `${targetKPIFolder}purchasesLight.svg`,
  purchasesRegular: `${targetKPIFolder}purchasesRegular.svg`,
  installsLight: `${targetKPIFolder}installsLight.svg`,
  installsRegular: `${targetKPIFolder}installsRegular.svg`,
  leadsLight: `${targetKPIFolder}leadsLight.svg`,
  leadsRegular: `${targetKPIFolder}leadsRegular.svg`,
  roas: `${svgLink}roas.svg`,
  registrations: `${svgLink}registrations.svg`,
  purchases: `${svgLink}purchases.svg`,
  leads: `${svgLink}leads.svg`,
  installs: `${svgLink}installs.svg`,
  impressions: `${svgLink}impressions.svg`,
  roas_indigo: `${svgLink}roas_indigo.svg`,
  registrations_indigo: `${svgLink}registrations_indigo.svg`,
  purchases_indigo: `${svgLink}purchases_indigo.svg`,
  leads_indigo: `${svgLink}leads_indigo.svg`,
  installs_indigo: `${svgLink}installs_indigo.svg`,
  impressions_indigo: `${svgLink}impressions_indigo.svg`,
  roas_blue: `${svgLink}roas_blue.svg`,
  registrations_blue: `${svgLink}registrations_blue.svg`,
  purchases_blue: `${svgLink}purchases_blue.svg`,
  leads_blue: `${svgLink}leads_blue.svg`,
  cloud_download_blue: `${svgLink}cloud_download_blue.svg`,
  impressions_blue: `${svgLink}impressions_blue.svg`,
  eyeBlue: `${svgLink}eye-blue.svg`,
  shopBlue: `${svgLink}shop-blue.svg`,
  graphBlue: `${svgLink}graph-blue.svg`,
  downloadBlue: `${svgLink}download-blue.svg`,
  accountBlue: `${svgLink}account-blue.svg`,
  bubbleChatBlue: `${svgLink}bubble-chat-blue.svg`,
  errorCat: `${svgLink}errorCat.svg`,
  maypleAvatar: `${svgLink}maypleAvatar.svg`,
  searchingIllustration: `${svgLink}searchingIllustration2.svg`,
  availability: `${svgLink}availability.svg`,
  // brands
  // appAnnie:                             `${brandsFolder}appAnnie.svg`,
  // autoPilot:                            `${brandsFolder}autoPilot.svg`,
  // buzzSumo:                             `${brandsFolder}buzzSumo.svg`,
  // canva:                                `${brandsFolder}canva.svg`,
  // constantContact:                      `${brandsFolder}constantContact.svg`,
  // googleKeywordPlanner:                 `${brandsFolder}googleKeywordPlanner.svg`,
  // klaviyo:                              `${brandsFolder}klaviyo.svg`,
  // mobileAction:                         `${brandsFolder}mobileAction.svg`,
  // sensorTower:                          `${brandsFolder}sensorTower.svg`,
  // seoSpider:                            `${brandsFolder}seoSpider.svg`,
  adRoll: `${brandsFolder}adRoll.svg`,
  adroll: `${brandsFolder}adroll.svg`,
  adwords: `${brandsFolder}adwords.svg`,
  ahrefs: `${brandsFolder}ahrefs.svg`,
  amazon: `${brandsFolder}amazon.svg`,
  amazonPpc: `${brandsFolder}amazonPpc.svg`,
  apple: `${brandsFolder}apple.svg`,
  bing: `${brandsFolder}bing.svg`,
  bingAds: `${brandsFolder}bingAds.svg`,
  bluesnapBadge: `${brandsFolder}bluesnapBadge.svg`,
  stripeBadge: `${brandsFolder}stripeBadge.svg`,
  buffer: `${brandsFolder}buffer.svg`,
  bufferPublish: `${brandsFolder}bufferPublish.svg`,
  clutch: `${brandsFolder}clutch.svg`,
  crazyEgg: `${brandsFolder}crazyEgg.svg`,
  cronofy: `${brandsFolder}cronofy.svg`,
  exchange: `${brandsFolder}Exchange.svg`,
  facebook: `${brandsFolder}facebook.svg`,
  facebookAds: `${brandsFolder}facebookAds.svg`,
  followerwonk: `${brandsFolder}followerwonk.svg`,
  fullStory: `${brandsFolder}fullStory.svg`,
  g2: `${brandsFolder}g2.svg`,
  getresponse: `${brandsFolder}getresponse.svg`,
  google: `${brandsFolder}google.svg`,
  googleAds: `${brandsFolder}googleAds.svg`,
  googleAnalytics: `${brandsFolder}googleAnalytics.svg`,
  googleSearchConsole: `${brandsFolder}googleSearchConsole.svg`,
  googleShopping: `${brandsFolder}googleShopping.svg`,
  googleTrends: `${brandsFolder}googleTrends.svg`,
  heap: `${brandsFolder}heap.svg`,
  hootsuite: `${brandsFolder}hootsuite.svg`,
  hotjar: `${brandsFolder}hotjar.svg`,
  instagram: `${brandsFolder}instagram.svg`,
  linkedIn: `${brandsFolder}linkedIn.svg`,
  linkedin: `${brandsFolder}linkedin.svg`,
  mailchimp: `${brandsFolder}mailchimp.svg`,
  majesticSeoTools: `${brandsFolder}majesticSeoTools.svg`,
  mayple: `${brandsFolder}mayple.svg`,
  mixpanel: `${brandsFolder}mixpanel.svg`,
  office: `${brandsFolder}Office.svg`,
  omnisend: `${brandsFolder}omnisend.svg`,
  optimizely: `${brandsFolder}optimizely.svg`,
  outbrain: `${brandsFolder}outbrain.svg`,
  outlook: `${brandsFolder}Outlook.svg`,
  paypal: `${brandsFolder}paypal.svg`,
  paypalWithText: `${brandsFolder}paypalWithText.svg`,
  pinterest: `${brandsFolder}pinterest.svg`,
  quora: `${brandsFolder}quora.svg`,
  reddit: `${brandsFolder}reddit.svg`,
  screamingFrog: `${brandsFolder}screamingFrog.svg`,
  selectom: `${brandsFolder}selectom.svg`,
  semrush: `${brandsFolder}semrush.svg`,
  sendGrid: `${brandsFolder}sendGrid.svg`,
  smartlook: `${brandsFolder}smartlook.svg`,
  snapchat: `${brandsFolder}snapchat.svg`,
  sproutSocial: `${brandsFolder}sproutSocial.svg`,
  taboola: `${brandsFolder}taboola.svg`,
  tiktok: `${brandsFolder}tiktok.svg`,
  twitter: `${brandsFolder}twitter.svg`,
  unsplash: `${brandsFolder}unsplash.svg`,
  usabilityHub: `${brandsFolder}usabilityHub.svg`,
  userbrain: `${brandsFolder}userbrain.svg`,
  vk: `${brandsFolder}vk.svg`,
  yahoo: `${brandsFolder}yahoo.svg`,
  yahooGemini: `${brandsFolder}yahooGemini.svg`,
  youtube: `${brandsFolder}youtube.svg`,
  // Billing
  amex: `${brandsFolder}amex.svg`,
  visa: `${brandsFolder}visa.svg`,
  mastercard: `${brandsFolder}mastercard.svg`,
  // Project main goal
  improveExistingCampaignsLight: `${mainGoalFolder}improveExistingCampaignsLight.svg`,
  improveExistingCampaignsRegular: `${mainGoalFolder}improveExistingCampaignsRegular.svg`,
  growMarketShareLight: `${mainGoalFolder}growMarketShareLight.svg`,
  growMarketShareRegular: `${mainGoalFolder}growMarketShareRegular.svg`,
  enterNewMarketsLight: `${mainGoalFolder}enterNewMarketsLight.svg`,
  enterNewMarketsRegular: `${mainGoalFolder}enterNewMarketsRegular.svg`,
  launchNewProductOrServicesLight: `${mainGoalFolder}launchNewProductOrServicesLight.svg`,
  launchNewProductOrServicesRegular: `${mainGoalFolder}launchNewProductOrServicesRegular.svg`,
  // objectives
  improve: `${svgLink}improve.svg`,
  review: `${svgLink}iris_scan.svg`,
  launch: `${svgLink}launch.svg`,
  worldWide: `${svgLink}worldwide.svg`,
  grow: `${svgLink}grow.svg`,
  improve_indigo: `${svgLink}improve_indigo.svg`,
  launch_indigo: `${svgLink}launch_indigo.svg`,
  worldWide_indigo: `${svgLink}worldwide_indigo.svg`,
  grow_indigo: `${svgLink}grow_indigo.svg`,
  improve_blue: `${svgLink}improve_blue.svg`,
  launch_blue: `${svgLink}launch_blue.svg`,
  worldWide_blue: `${svgLink}worldwide_blue.svg`,
  grow_blue: `${svgLink}grow_blue.svg`,
  buildingBlue: `${svgLink}building-blue.svg`,
  loveBlue: `${svgLink}love-blue.svg`,
  improve_teal: `${svgLink}improve_teal.svg`,
  grow_teal: `${svgLink}grow_teal.svg`,
  maypler_illustration_Proposals: `${svgLink}maypler_illustration_Proposals.svg`,
  maypler_illustration_EasyStart: `${svgLink}maypler_illustration_EasyStart.svg`,
  maypler_illustration_ImprovedResults: `${svgLink}maypler_illustration_ImprovedResults.svg`,
  maypler_llustration_ServiceLevel: `${svgLink}maypler_llustration_ServiceLevel.svg`,
  maypler_illustration_QualityReports: `${svgLink}maypler_illustration_QualityReports.svg`,
  maypler_illustration_Advice: `${svgLink}maypler_illustration_Advice.svg`,
  data_file_check: `${svgLink}data_file_check.svg`,
  task_list_multiple: `${svgLink}task_list_multiple.svg`,
  // Customer type
  experiencedLight: `${svgLink}experiencedLight.svg`,
  experiencedRegular: `${svgLink}experiencedRegular.svg`,
  noviceLight: `${svgLink}noviceLight.svg`,
  noviceRegular: `${svgLink}noviceRegular.svg`,
  // Remote work
  remoteWorkDomesticLight: `${svgLink}remoteWorkDomesticLight.svg`,
  remoteWorkOnTargetLight: `${svgLink}remoteWorkOnTargetLight.svg`,
  remoteWorkNoPreferenceLight: `${svgLink}remoteWorkNoPreferenceLight.svg`,
  // Auto suggest skill
  AUTO_SUGGEST_SKILLS_AUTO_LIGHT: `${svgLink}AUTO_SUGGEST_SKILLS_AUTO_LIGHT.svg`,
  AUTO_SUGGEST_SKILLS_MANUAL_LIGHT: `${svgLink}AUTO_SUGGEST_SKILLS_MANUAL_LIGHT.svg`,
  // launchTimeFramePreference
  UP_TO_ONE_WEEK_LIGHT: `${launchTimeFramePreferenceFolder}UP_TO_ONE_WEEK_LIGHT.svg`,
  UP_TO_ONE_MONTH_LIGHT: `${launchTimeFramePreferenceFolder}UP_TO_ONE_MONTH_LIGHT.svg`,
  ONE_MONTH_OR_MORE_LIGHT: `${launchTimeFramePreferenceFolder}ONE_MONTH_OR_MORE_LIGHT.svg`,
  UNSURE_LIGHT: `${launchTimeFramePreferenceFolder}UNSURE_LIGHT.svg`,
  UP_TO_ONE_WEEK_REGULAR: `${launchTimeFramePreferenceFolder}UP_TO_ONE_WEEK_REGULAR.svg`,
  UP_TO_ONE_MONTH_REGULAR: `${launchTimeFramePreferenceFolder}UP_TO_ONE_MONTH_REGULAR.svg`,
  ONE_MONTH_OR_MORE_REGULAR: `${launchTimeFramePreferenceFolder}ONE_MONTH_OR_MORE_REGULAR.svg`,
  UNSURE_REGULAR: `${launchTimeFramePreferenceFolder}UNSURE_REGULAR.svg`,
  // theme
  mayple_logo: `${svgLink}mayple_logo.svg`,
  door_blue: `${svgLink}door_blue.svg`,
  scholar_blue: `${svgLink}scholar_blue.svg`,
  chat_bubble: `${svgLink}chat_bubble.svg`,
  user_add: `${svgLink}user_add.svg`,
  user_female_add: `${svgLink}user_female_add.svg`,
  new_message: `${svgLink}phone-new-message.svg`,
  location1: `${svgLink}location1.svg`,
  company_indigo: `${svgLink}company_indigo.svg`,
  company_blue: `${svgLink}company_blue.svg`,
  company1: `${svgLink}company1.svg`,
  marketer_blue: `${svgLink}marketer_blue.svg`,
  marketer_indigo: `${svgLink}marketer_indigo.svg`,
  marketer1: `${svgLink}marketer1.svg`,
  credit_card: `${svgLink}credit_card.svg`,
  cash1: `${svgLink}cash1.svg`,
  cash2: `${svgLink}cash2.svg`,
  cash3: `${svgLink}cash3.svg`,
  cash1_indigo: `${svgLink}cash1_indigo.svg`,
  cash2_indigo: `${svgLink}cash2_indigo.svg`,
  cash3_indigo: `${svgLink}cash3_indigo.svg`,
  cash1_blue: `${svgLink}cash1_blue.svg`,
  cash2_blue: `${svgLink}cash2_blue.svg`,
  cash3_blue: `${svgLink}cash3_blue.svg`,
  testtube_indigo: `${svgLink}testtube_indigo.svg`,
  businessman_indigo: `${svgLink}businessman_indigo.svg`,
  testtube_blue: `${svgLink}testtube_blue.svg`,
  workBlue: `${svgLink}work-blue.svg`,
  mapBlue: `${svgLink}map-blue.svg`,
  vacationBlue: `${svgLink}vacation-blue.svg`,
  stopwatchBlue: `${svgLink}stopwatch-blue.svg`,
  speedBlue: `${svgLink}speed-blue.svg`,
  binocularsBlue: `${svgLink}binoculars-blue.svg`,
  businessman_blue: `${svgLink}businessman_blue.svg`,
  target_teal: `${svgLink}target_teal.svg`,
  increase_awareness_indigo: `${svgLink}increase_awareness_indigo.svg`,
  reception_table_indigo: `${svgLink}reception_table_indigo.svg`,
  microphone_blue: `${svgLink}microphone_blue.svg`,
  reception_table_blue: `${svgLink}reception_table_blue.svg`,
  announce: `${svgLink}announce.svg`,
  stop_hand: `${svgLink}stop_hand.svg`,
  test_tube: `${svgLink}test_tube.svg`,
  briefcaseYellow: `${svgLink}briefcase-outlined-yellow.svg`,
  starYellow: `${svgLink}star-outlined-yellow.svg`,
  clockYellow: `${svgLink}clock-outlined-yellow.svg`,
  forbiddenYellow: `${svgLink}forbidden-outlined-yellow.svg`,
  focusYellow: `${svgLink}focus-outlined-yellow.svg`,
  lightbulbOutlinedYellow: `${svgLink}lightbulb-outlined-yellow.svg`,
  lightbulbYellow: `${svgLink}lightbulb-yellow.svg`,
  bubbleYellow: `${svgLink}bubble-yellow.svg`,
  bubbleBlue: `${svgLink}bubble-blue.svg`,
  paymentSuccess: `${svgLink}payment-success.svg`,
  like_blue: `${svgLink}like_blue.svg`,
  notepad_touch_blue: `${svgLink}notepad_touch_blue.svg`,
  hourglass_blue: `${svgLink}hourglass_blue.svg`,
  chatYellow: `${svgLink}chat_yellow.svg`,
  settings: `${svgLink}settings_nofill.svg`,
  search_employees: `${svgLink}search_employees.svg`,
  like: `${svgLink}like.svg`,
  uploadGreen: `${svgLink}upload-green.svg`,
  reviewBlue: `${svgLink}review-blue.svg`,
  checkHexagonGreen: `${svgLink}check-hexagon-green.svg`,
  chattingPerson: `${svgLink}chatting_person.svg`,
  auditPreviewCard: `${svgLink}audit_preview_card.svg`,
  briefPreviewCard: `${svgLink}brief_mountain.svg`,
  tasksListCheck: `${svgLink}tasks_list_check.svg`,
  spellCheck: `${svgLink}spell_check.svg`,
  shipmentDeliver: `${svgLink}shipment-deliver.svg`,
  bullseye: `${svgLink}bullseye.svg`,
  miniBriefBaloon: `${svgLink}miniBriefBalloon.svg`,
  blueWomanBlondHair: `${svgLink}blueWomanBlondHair.svg`,
  underReview: `${svgLink}underReview.svg`,
  thumbsUp: `${svgLink}thumbsUp.svg`,
  send: `${svgLink}send.svg`,
  wireTransfer: `${svgLink}savingBankInternational.svg`,
  puzzle: `${svgLink}puzzle.svg`,
  frame: `${svgLink}frame.svg`,

  // Marketer match related
  FREELANCER: `${marketerMatchFolder}FREELANCER.svg`,
  DIGITAL_AGENCY: `${marketerMatchFolder}DIGITAL_AGENCY.svg`,
  matchedMarketingSkills: `${marketerMatchFolder}matchedMarketingSkills.svg`,
  matchedMonthlyBudgetRangeAmount: `${marketerMatchFolder}matchedMonthlyBudgetRangeAmount.svg`,
  matchedLanguages: `${marketerMatchFolder}matchedLanguages.svg`,
  matchedIndustries: `${marketerMatchFolder}matchedIndustries.svg`,
  matchedCountries: `${marketerMatchFolder}matchedCountries.svg`,
  matchedProductBusinessModelExpertise: `${marketerMatchFolder}matchedProductBusinessModelExpertise.svg`,
  matchedTargetKPIExpertise: `${marketerMatchFolder}matchedTargetKPIExpertise.svg`,
  monthlyBudgetRangeAmount: `${marketerMatchFolder}monthlyBudgetRangeAmount.svg`,
  languageExpertise: `${marketerMatchFolder}languageExpertise.svg`,
  industryExpertise: `${marketerMatchFolder}industryExpertise.svg`,
  countryExpertise: `${marketerMatchFolder}countryExpertise.svg`,
  productBusinessModelExpertise: `${marketerMatchFolder}productBusinessModelExpertise.svg`,
  targetKPIExpertise: `${marketerMatchFolder}targetKPIExpertise.svg`,
  checkBadgeInv: `${marketerMatchFolder}checkBadgeInv.svg`,
  productCostRange: `${marketerMatchFolder}productCostRange.svg`,
  clientStageExpertise: `${marketerMatchFolder}clientStageExpertise.svg`,
  // brief
  briefMotivationStart: `${svgLink}deepBrief/brief-motivation-start.svg`,
  briefMotivation25Percent: `${svgLink}deepBrief/brief-motivation-25.svg`,
  briefMotivation70Percent: `${svgLink}deepBrief/brief-motivation-70.svg`,
  briefMotivation95Percent: `${svgLink}deepBrief/brief-motivation-95.svg`,
  basicBrief: `${svgLink}deepBrief/basicBrief.svg`,
  businessInfo: `${svgLink}deepBrief/businessInfo.svg`,
  // warmUp
  warmUpIllustrationIPad: `${svgLink}deepBrief/ipad-illustration.svg`,
  // LandingPage
  maypleLogoDark: `${svgLink}landingPage/maypleLogoDark.svg`,
  maypleLogoWhite: `${svgLink}landingPage/maypleLogoWhite.svg`,
  greenElipses: `${svgLink}landingPage/firstSection/greenElipses.svg`,
  niddles: `${svgLink}landingPage/firstSection/niddles.svg`,
  slantLines: `${svgLink}landingPage/firstSection/slantLines.svg`,
  triangles: `${svgLink}landingPage/firstSection/triangles.svg`,
  yellowElipse: `${svgLink}landingPage/firstSection/yellowElipse.svg`,
  content: `${svgLink}landingPage/secondSection/content.svg`,
  conversion: `${svgLink}landingPage/secondSection/conversion.svg`,
  media: `${svgLink}landingPage/secondSection/media.svg`,
  socialMedia: `${svgLink}landingPage/secondSection/socialMedia.svg`,
  strategy: `${svgLink}landingPage/secondSection/strategy.svg`,
  compTable: `${svgLink}landingPage/thirdToEightSections/compTable.svg`,
  leftSideDrops: `${svgLink}landingPage/ninthSection/leftSideDrops.svg`,
  rightSideDrops: `${svgLink}landingPage/ninthSection/rightSideDrops.svg`,
  // Proposal
  questionMark: `${svgLink}proposal/question-mark.svg`,
  playVideo: `${svgLink}proposal/play-video.svg`,
  number1: `${svgLink}proposal/number-1.svg`,
  number2: `${svgLink}proposal/number-2.svg`,
  number3: `${svgLink}proposal/number-3.svg`,
  checkboxDone: `${svgLink}proposal/checkboxDone.svg`,
  fireworks: `${svgLink}proposal/fireworks.svg`,
  timeClockFire: `${svgLink}proposal/time-clock-fire.svg`,
  checkBadge: `${svgLink}proposal/checkBadge.svg`,
  // Opportunities
  acceptedOpportunityStep1: `${opportunitiesFolder}accepted-opportunity-step-1.svg`,
  acceptedOpportunityStep2: `${opportunitiesFolder}accepted-opportunity-step-2.svg`,
  acceptedOpportunityStep3: `${opportunitiesFolder}accepted-opportunity-step-3.svg`,
  // Upload Marketing Plan
  approvedIndicator: `${marketingPlanFolder}approvedIndicator.svg`,
  clientApprovalIndicator: `${marketingPlanFolder}clientApprovalIndicator.svg`,
  inReviewIndicator: `${marketingPlanFolder}inReviewIndicator.svg`,
  timeLeftIndicator: `${marketingPlanFolder}timeLeftIndicator.svg`,
  noMeetingWasSetIndicator: `${marketingPlanFolder}noMeetingWasSetIndicator.svg`,
  //
  FolderEmpty1: `${streamlineIconsFolder}FolderEmpty1.svg`,
  House1: `${streamlineIconsFolder}House1.svg`,
  TaskListText1: `${streamlineIconsFolder}TaskListText1.svg`,
  SingleManCircle: `${streamlineIconsFolder}SingleManCircle.svg`,
  SingleNeutralActionsAdd: `${streamlineIconsFolder}SingleNeutralActionsAdd.svg`,
  HammerWench: `${streamlineIconsFolder}HammerWench.svg`,
  MoneyWallet: `${streamlineIconsFolder}MoneyWallet.svg`,
  Flash1: `${streamlineIconsFolder}Flash1.svg`,
  ArchiveFolder: `${streamlineIconsFolder}ArchiveFolder.svg`,
  CalendarClock: `${streamlineIconsFolder}CalendarClock.svg`,
  ArrowRight1: `${streamlineIconsFolder}ArrowRight1.svg`,
  ConnectDeviceCancel: `${streamlineIconsFolder}ConnectDeviceCancel.svg`,
  TimeStopwatch3Quarters: `${streamlineIconsFolder}TimeStopwatch3Quarters.svg`,
  MessagesBubbleForward: `${streamlineIconsFolder}MessagesBubbleForward.svg`,
  PhoneCircle: `${streamlineIconsFolder}PhoneCircle.svg`,
  ReadEmailAt: `${streamlineIconsFolder}ReadEmailAt.svg`,
  BookCloseBookmark1: `${streamlineIconsFolder}BookCloseBookmark1.svg`,
  CashPaymentBag: `${streamlineIconsFolder}CashPaymentBag.svg`,
};
