import React, { FC } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Grid from '@material-ui/core/Grid';

import { QueryErrorMessageProps } from './types';
import CalloutMessage from '../CalloutMessage';
import { TextDisplay } from '../../display';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  }),
);

const QueryErrorMessage: FC<QueryErrorMessageProps> = (props) => {
  const { error, message, hideServerError = false } = props;
  const classes = useStyles(props);

  if (!error) {
    return null;
  }

  const graphQLErrors = error.graphQLErrors || [];
  const errMsg = error.message || 'Unknown error';

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {message && <CalloutMessage message={message} type="error" margin />}
        </Grid>
        {!hideServerError && (
          <Grid item xs={12}>
            <CalloutMessage message={`Received error: ${errMsg}`} type="error" margin />
          </Grid>
        )}
        {!hideServerError && graphQLErrors.length > 0 && (
          <Grid item xs={12}>
            <TextDisplay>Additional info:</TextDisplay>
          </Grid>
        )}
        {!hideServerError &&
          graphQLErrors.length > 0 &&
          graphQLErrors.map((msg) => (
            <Grid item xs={12} key={`${msg}-graphQLErrors-QueryErrorMessage`}>
              <pre>{msg}</pre>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default QueryErrorMessage;
