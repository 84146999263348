/* eslint-disable no-unused-vars */
import React, { FC } from 'react';

import ErrorPage from './ErrorPage';
import { clientLogger } from '../../../../fe_common/client/services/logger';

interface UnexpectedErrorPageProps {
  error: any;
  componentStack: any;
  resetError: any;
}

const UnexpectedErrorPage: FC<UnexpectedErrorPageProps> = (props) => {
  const { error, resetError } = props;

  clientLogger.error(`Error in cpanel:\n\n${JSON.stringify(error.toString(), null, 2)}`);

  const onGotoHomepageClickHandler = () => {
    // When resetError() is called it will remove the Fallback component
    // and render the Sentry ErrorBoundary's children in their initial state
    resetError();
  };

  return <ErrorPage error={error} onGotoHomepageClick={onGotoHomepageClickHandler} />;
};

export default UnexpectedErrorPage;
