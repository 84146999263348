import { useMemo } from 'react';
import { GoogleSlideshowEmbedProps } from './types';

export const GOOGLE_SLIDE_REGEXP = /^(https?:\/\/docs\.google\.com\/presentation\/.*(?=\/edit))(.*)$/i;

const getGoogleSlidePreviewUrl = (url: string, start: boolean, loop: boolean, delay: number): string => {
  if (!GOOGLE_SLIDE_REGEXP.test(url)) {
    return url;
  }

  const previewLink = url.replace(GOOGLE_SLIDE_REGEXP, '$1/preview');
  const newUrl = new URL(previewLink);

  newUrl.searchParams.append('start', String(start));
  newUrl.searchParams.append('loop', String(loop));
  newUrl.searchParams.append('delayms', String(delay));

  return newUrl.href.toString();
};

const useGoogleSlideshowEmbed = (props: GoogleSlideshowEmbedProps): { previewSrc: string } => {
  const { src, start = false, loop = false, delay = 3000 } = props;

  const previewSrc = useMemo(
    (): string => getGoogleSlidePreviewUrl(src, start, loop, delay),
    [src, start, loop, delay],
  );

  return { previewSrc };
};

export default useGoogleSlideshowEmbed;
