import { useState } from 'react';
import { Filter, FilteredChangeFunction, SortedChangeFunction, SortingRule } from 'react-table-6';

type ReactTableDataFilterSorter = {
  dataTableFilter: Filter[];
  onFilteredChangeHandler: FilteredChangeFunction;
  dataTableSort: SortingRule[];
  onSortedChangeHandler: SortedChangeFunction;
};

const useReactTableDataFilterSorter = (): ReactTableDataFilterSorter => {
  const [dataTableFilter, setDataTableFilter] = useState<Filter[]>([]);
  const [dataTableSort, setDataTableSort] = useState<SortingRule[]>([]);

  const onSortedChangeHandler: SortedChangeFunction = (newSorted /* , column, shiftKey */) => {
    setDataTableSort(newSorted);
  };

  const onFilteredChangeHandler: FilteredChangeFunction = (filtered /* , column */) => {
    setDataTableFilter(filtered);
  };

  return {
    dataTableFilter,
    onFilteredChangeHandler,
    dataTableSort,
    onSortedChangeHandler,
  };
};

export default useReactTableDataFilterSorter;
