import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { DialogFullScreenTitleProps } from '../../types';
import DialogActions from '../DialogActions';
import DialogCloseButton from '../DialogCloseButton';

import useStyles from './style';

const DialogFullScreenTitle: React.FC<DialogFullScreenTitleProps> = (props) => {
  const classes = useStyles(props);
  const { title, subtitle, onClose, closeButtonProps, actions } = props;

  return (
    <AppBar color="transparent" position="fixed">
      <Toolbar className={classes.toolbarRoot}>
        <DialogCloseButton onClose={onClose} closeButtonProps={closeButtonProps} />
        <div className={classes.titlesWrapper}>
          {!!title && <div className={classes.title}>{title}</div>}
          {!!subtitle && <div className={classes.subtitle}>{subtitle}</div>}
        </div>
        {!!actions && <DialogActions actions={actions} classes={{ root: classes.dialogActions }} />}
      </Toolbar>
    </AppBar>
  );
};

export default DialogFullScreenTitle;
