import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Link } from 'react-router-dom';

import { iconCodes } from '../../../app/icons';
import { colors } from '../../../app/theme';
import { SelectedEntityViewer } from '../SelectedEntityViewer';
import { TextDisplay } from '../../display';

export function InActiveIntegrationIndicator(props) {
  const { integration } = props;
  if (!integration) {
    return null;
  }

  const { integrationStatus, integrationType } = integration;
  let integrationUser = null;
  let channel = null;
  let icon = iconCodes.account;
  if (integrationType === 'GOOGLE_ADWORDS') {
    integrationUser = integration.googleAdWordsIntegrationUser;
    icon = iconCodes.google;
    channel = 'AdWords';
  }
  if (integrationType === 'FACEBOOK') {
    integrationUser = integration.facebookIntegrationUser;
    icon = iconCodes.facebook;
    channel = 'Facebook';
  }

  if (!integrationUser || integrationStatus === 'ACTIVE') {
    return null;
  }

  return (
    <Paper style={{ padding: 4, margin: '16px 0' }}>
      <TextDisplay colorOverride={colors.red}>
        <b>Warning:</b> The current {channel} user has been disabled.
        <Tooltip
          title={
            <span style={{ fontSize: 16 }}>
              To re-activate the integration please go to
              <Link style={{ color: '#5FE3C3' }} to="/user/accounts">
                {' '}
                Account Settings{' '}
              </Link>
              and add the shown user below.
            </span>
          }
        >
          <HelpOutlineIcon />
        </Tooltip>
      </TextDisplay>
      <TextDisplay colorOverride={colors.red}>
        This means we will not be able to show current performance data and perform necessary actions.
      </TextDisplay>
      <SelectedEntityViewer entity={integrationUser} icon={icon} />
    </Paper>
  );
}

InActiveIntegrationIndicator.propTypes = {
  integration: PropTypes.object.isRequired,
};
