import { STATIC_ASSETS_CDN_URL } from './consts';

const host = `${STATIC_ASSETS_CDN_URL}/logo`;

// eslint-disable-next-line import/prefer-default-export
export const logos: Record<string, string> = {
  // links to Mayple's logos variations:
  logoDark: `${host}/logo_dark.svg`,
  logoDarkStaging: `${host}/logo_dark_staging.svg`,
  logoDarkTesting: `${host}/logo_dark_testing.svg`,
  logoDarkDev: `${host}/logo_dark_develop.svg`,
  logoDarkPadding: `${host}/logo_dark_padding.png`,
  logoLight: `${host}/logo_light.svg`,
  logoCherry: `${host}/logo_cherry.svg`,
  logoMonochromeDark: `${host}/logo_monochrome_dark.svg`,
  logoMonochromeLight: `${host}/logo_monochrome_light.svg`,
  logoMonochromeCherry: `${host}/logo_monochrome_cherry.svg`,
  logoNoTextDark: `${host}/logo_notext_dark.svg`,
  logoNoTextLight: `${host}/logo_notext_light.svg`,
  logoNoTextCherry: `${host}/logo_notext_cherry.svg`,
  logoSmall: `${host}/logoSmall.svg`,
  // link to other needed logos:
  facebook: `${host}/facebook.svg`,
  walmart: `${host}/walmart.svg`,
  ebay: `${host}/ebay.svg`,
  expedia: `${host}/expedia.svg`,
  skyscanner: `${host}/skyscanner.svg`,
  mailchimpLogo: `${host}/mailchimp/mailchimp-logo.svg`,
  mailchimpLogoSmall: `${host}/mailchimp/freddieIcon.svg`,
};
