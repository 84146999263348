import React from 'react';

import { DialogTitleProps } from '../../types';
import DialogCloseButton from '../DialogCloseButton';
import TextDisplay from '../../../TextDisplay';

import useStyles from './style';

const DialogTitle: React.FC<DialogTitleProps> = (props) => {
  const classes = useStyles(props);
  const { title, subtitle, onClose, closeButtonProps } = props;

  return (
    <div className={classes.root}>
      <div className={classes.titles}>
        {!!title && <TextDisplay variant="h5">{title}</TextDisplay>}
        {!!subtitle && <TextDisplay variant="subtitle2">{subtitle}</TextDisplay>}
      </div>
      <DialogCloseButton
        onClose={onClose}
        closeButtonProps={closeButtonProps}
        classes={{ buttonRoot: classes.closeButton }}
      />
    </div>
  );
};

export default DialogTitle;
