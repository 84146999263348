/* eslint-disable camelcase */
import {
  AccountType,
  Country,
  CreditCardPaymentAgreement,
  CurrentWorkStatusType,
  EntityOperationResponse,
  IndustryCategory,
  IndustrySubCategory,
  ManualPaymentAgreement,
  MarketerType,
  MarketingServiceType,
  PaypalOpenPaymentAgreement,
  State,
} from '@mayple/types';
import { ExecutionResult } from 'graphql';

declare global {
  interface Window {
    Egg: any;
    bluesnap: any;
    mayple_analytics: any;
    __APOLLO_CLIENT__: any;
  }
}

export enum Environment {
  PORKY = 'porky',
  STORKY = 'storky',
  TORKY = 'torky',
  DORKY = 'dorky',
}

/** *************************************************************************** */
/** Enums types                                                                 */
/** *************************************************************************** */
export interface LabelValueProps<T = any> {
  label: string;
  value: T;
  svgIcon?: string;
  fontIcon?: string;
  tooltip?: string;
  isDeprecated?: boolean;
  isDisabled?: boolean;
}

export interface IndustryLabelValueProps extends LabelValueProps<IndustrySubCategory> {
  industry: IndustryCategory;
}

export type IndustryLabelValuePair = {
  category: IndustryCategory;
  value: IndustrySubCategory;
};

export type EnumKeysAsStrings<TEnumType> = keyof TEnumType;

export type Lookup<TEnumType> = {
  [key in EnumKeysAsStrings<TEnumType>]: string;
};

export type MarketerTypeKeys = EnumKeysAsStrings<typeof MarketerType>;
export type MarketerTypeLabelsLookup = {
  [key in MarketerTypeKeys]: string;
};

export type CountriesKeys = EnumKeysAsStrings<typeof Country>;
export type CountriesLabelsLookup = {
  [key in CountriesKeys]: string;
};

export type StatesKeys = EnumKeysAsStrings<typeof State>;
export type StatesLabelsLookup = {
  [key in StatesKeys]: string;
};

export type MarketingServiceTypeKeys = EnumKeysAsStrings<typeof MarketingServiceType>;
export type MarketingServiceTypeLabelsLookup = {
  [key in MarketingServiceTypeKeys]: string;
};

export type CurrentWorkStatusTypeKeys = EnumKeysAsStrings<typeof CurrentWorkStatusType>;
export type WorkStatusLookup = {
  [key in CurrentWorkStatusTypeKeys]: string;
};

export enum ENTITY_TYPE {
  COMPANY = 'Company',
  MARKETER = 'Marketer',
  USER = 'User',
  INTERNAL_TEAM = 'Internal Account',
}
export const ENTITY_TYPES = Object.values(ENTITY_TYPE);

export type Entity = {
  id: number | undefined;
  uuid: string | undefined;
  name: string | undefined;
  displayImageUrl: string | undefined;
  accountType: AccountType | undefined;
};

export enum RemoteWork {
  DOMESTIC = 'DOMESTIC',
  ON_TARGET = 'ON_TARGET',
  NO_PREFERENCE = 'NO_PREFERENCE',
  NA = 'N/A',
}

export type PaymentAgreement = CreditCardPaymentAgreement | PaypalOpenPaymentAgreement | ManualPaymentAgreement | null;

export type ReactFilestackOptions = {
  accept?: string[];
  maxFiles?: number;
  minFiles?: number;
  maxSize?: number;
};

export type ErrorResult = {
  error: boolean;
  errorMessage: string;
};

export type EntityOperationResponseExecutionResult<K = string | number | symbol> = ExecutionResult<
  // @ts-ignore
  Record<K, EntityOperationResponse>
>;

export type NavigationParams = {
  params?: Record<string, any>;
  step?: string;
};

export enum WWCountry {
  WW = 'WW',
}

export const ExtendedCountry = { ...Country, ...WWCountry };
export type ExtendedCountry = Country | WWCountry; // typeof ExtendedCountry;

export enum AutoSuggestSkills {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}
