import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { DialogCloseButtonProps } from '../../types';
import useStyles from './style';

const DialogCloseButton: React.FC<DialogCloseButtonProps> = (props) => {
  const { onClose, closeButtonProps } = props;

  const classes = useStyles(props);

  return (
    <Tooltip title="Close" placement="left" classes={{ tooltip: classes.tooltip }}>
      <IconButton
        type="button"
        aria-label="Close"
        color="default"
        onClick={onClose}
        classes={{ root: classes.buttonRoot }}
        {...closeButtonProps}
      >
        <CloseIcon />
      </IconButton>
    </Tooltip>
  );
};

export default DialogCloseButton;
