import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { clearCpanelVersionCache, forceRefreshPage, getCpanelVersionFromCache } from './logic';
import { config } from '../../config';
import { getCpanelServerVersion } from '../../services/api/cpanel';

import { Environment } from '../../../fe_common/client/app/types';
import { clientLogger } from '../../../fe_common/client/services/logger';
import { setToLocalStorage } from '../../../fe_common/client/services/utils';
import { Dialog, TextDisplay } from '../../../fe_common/client/components/display';

import useStyles from './style';

const IN_2_MIN = 1000 * 60 * 2;
const EVERY_5_MIN = 1000 * 60 * 5;

/**
 * This component verifies that the user always has the newest built version of cpanel.
 * We might deploy new versions in the background, that have some changes in the DB schema. in that case we need to
 * update the graphQL queries in the client, that will be mismatched the new schema, that causes errors in the
 * graphQL server.
 *
 * Notice, The preferred way to do this is via web sockets, checking when the socket has disconnected.
 * But due to current infrastructure (18/2/2020), A simple http request is the better way for now.
 */
const EnsureLatestCpanelVersion: FC = () => {
  const classes = useStyles();
  const [versionsMatched, setVersionsMatched] = useState(true);
  const [didReportException, setDidReportException] = useState(false);

  const timeoutId = useRef<number | undefined>();
  const refreshTimeOutId = useRef<number | undefined>();

  const getVersion = useCallback(async () => {
    const response = (await getCpanelServerVersion()) || {};
    const cpanelServerVersion = response.version;
    const cpanelClientVersion = getCpanelVersionFromCache();

    // Check if version already set
    if (cpanelClientVersion) {
      // Check if versions are matched
      if (cpanelServerVersion != null && cpanelClientVersion !== cpanelServerVersion) {
        clientLogger.info('Current server version is different than client version');
        // Render the dialog for the user
        setVersionsMatched(false);
        // Refresh the page after 120 sec
        // console.log(`will refresh in ${IN_2_MIN} ms`);
        refreshTimeOutId.current = window.setTimeout(forceRefreshPage, IN_2_MIN);
        // Exit func.
        return;
      }
    } else if (cpanelServerVersion != null) {
      // Set latest server version
      setToLocalStorage('_mayple_client_version', cpanelServerVersion);
    } else if (!didReportException && config.env !== Environment.DORKY) {
      setDidReportException(true);
      // Exit func.
      return;
    }

    // Run this check every 5 min.
    // console.log(`will getVersion in ${EVERY_5_MIN} ms`);
    timeoutId.current = window.setTimeout(getVersion, EVERY_5_MIN);
  }, [didReportException]);

  useEffect(() => {
    // First clear cached version that might be old.
    clearCpanelVersionCache();
    getVersion();

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      if (refreshTimeOutId.current) {
        clearTimeout(refreshTimeOutId.current);
      }
    };
  }, [getVersion]);

  if (versionsMatched) {
    return null;
  }

  return (
    <Dialog
      hideDialogTitle
      isOpen={!versionsMatched}
      actions={[
        {
          label: 'REFRESH NOW',
          variant: 'contained',
          color: 'primary',
          onClick: forceRefreshPage,
        },
        {
          label: 'LET ME SAVE MY PROGRESS FIRST',
          onClick: () => {
            setVersionsMatched(true);
            clearTimeout(refreshTimeOutId.current);
            timeoutId.current = window.setTimeout(getVersion, EVERY_5_MIN);
          },
        },
      ]}
    >
      <div className={classes.contentRoot}>
        <TextDisplay variant="h6" gutterBottom>
          This page needs to freshen up with a new version
        </TextDisplay>
        <TextDisplay variant="caption">
          It will auto refresh in 120 second. You have time to conquer the world, or just:
        </TextDisplay>
      </div>
    </Dialog>
  );
};

export default EnsureLatestCpanelVersion;
