import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    title: {
      width: '100%',
      // fontWeight: 'bold',
    },
    tooltip: {},
    tooltipPopper: {
      marginLeft: '0.3rem',
      fontSize: '1em',
    },
  }),
);

export default useStyles;
