import { IndustryCategory, IndustrySubCategory } from '@mayple/types';

import { INDUSTRIES_GROUPS, INDUSTRIES_LABELS_LOOKUP, INDUSTRIES_PAIRS } from './enums';
import { getIndustryLabel } from './helper';
import { INDUSTRIES_CATEGORIES_LABELS } from './consts';

const industriesLabelsLookup: Record<IndustryCategory | IndustrySubCategory, string> =
  INDUSTRIES_LABELS_LOOKUP as Record<IndustryCategory | IndustrySubCategory, string>;

export {
  INDUSTRIES_PAIRS,
  INDUSTRIES_LABELS_LOOKUP,
  INDUSTRIES_GROUPS,
  INDUSTRIES_CATEGORIES_LABELS,
  getIndustryLabel,
  industriesLabelsLookup,
};
