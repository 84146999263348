/**
 * This service should be used to monitor errors occurring that are not captured by the error boundary.
 * For example, We try to load the package page and the query fails. In that case the client will not crash, Just that
 * the user will see a blank page. This can be a CRITICAL level case that you want to immediately report and handle as
 * it is part of the main sales flow in the app.
 *
 * More info on how to use Sentry reporting
 * https://docs.sentry.io/platforms/javascript/guides/react/usage/
 */

import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/types';

/* ERROR REPORTING */

// To keep our tags standard
enum TagKey {
  component = 'component',
}

interface ReportOptions {
  tags?: Record<TagKey, string>;
  level?: SeverityLevel;
}

export const reportError = (error: Error, options: ReportOptions = {}): void => {
  const { tags, level = 'error' } = options;

  Sentry.withScope((scope) => {
    if (tags) {
      Object.entries(tags).forEach(([tag, value]) => {
        scope.setTag(tag, value);
      });
    }

    Sentry.captureException(error, {
      level,
    });
  });
};
/* ****************************************************************************************************************** */

/* CUSTOM MESSAGES REPORTING */
export const reportMessage = (message: string): void => {
  Sentry.captureMessage(message);
};
/* ****************************************************************************************************************** */
