import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {},
    buttonRoot: {},
  }),
);

export default useStyles;
