import { IndustryCategory, IndustrySubCategory } from '@mayple/types';

export const INDUSTRIES_CATEGORIES_LABELS: Record<IndustryCategory, string> = {
  [IndustryCategory.AGRICULTURE]: 'Agriculture',
  [IndustryCategory.ART_AND_ENTERTAINMENT]: 'Art & Entertainment',
  [IndustryCategory.AUTOMOTIVE]: 'Automotive',
  [IndustryCategory.BABY]: 'Baby',
  [IndustryCategory.BEAUTY]: 'Beauty',
  [IndustryCategory.CONSTRUCTION]: 'Construction',
  [IndustryCategory.CONSUMER_GOODS]: 'Consumer goods',
  [IndustryCategory.DESIGN]: 'Design',
  [IndustryCategory.ELECTRONICS]: 'Electronics',
  [IndustryCategory.EDUCATION]: 'Education',
  [IndustryCategory.ENERGY_AND_MINING]: 'Energy & Mining',
  [IndustryCategory.FINANCE]: 'Finance',
  [IndustryCategory.FOOD_AND_DRINK]: 'Food & Drink',
  [IndustryCategory.GAMING]: 'Gaming',
  [IndustryCategory.HEALTH_AND_WELLNESS]: 'Health & Wellness',
  [IndustryCategory.HOME_AND_GARDEN]: 'Home & Garden',
  [IndustryCategory.LOGISTICS]: 'Logistics',
  [IndustryCategory.MANUFACTURING]: 'Manufacturing',
  [IndustryCategory.MEDIA_AND_COMMUNICATIONS]: 'Media & Communications',
  [IndustryCategory.NONPROFIT_AND_GOVERNMENT]: 'Nonprofit & Government',
  [IndustryCategory.PETS]: 'Pets',
  [IndustryCategory.PROFESSIONAL_SERVICES]: 'Professional Services',
  [IndustryCategory.REAL_ESTATE]: 'Real estate',
  [IndustryCategory.RETAIL]: 'Retail',
  [IndustryCategory.SOCIAL_AND_COMMUNITY]: 'Social & Community',
  [IndustryCategory.SPORTS_OUTDOORS_AND_FITNESS]: 'Sports, Outdoors & Fitness',
  [IndustryCategory.STYLE_AND_FASHION]: 'Style & Fashion',
  [IndustryCategory.TECHNOLOGY]: 'Technology',
  [IndustryCategory.TRAVEL]: 'Travel',
};

export const DEPRECATED_INDUSTRIES_SUBCATEGORIES = new Set([IndustrySubCategory.CBD_PRODUCTS]);

export const INDUSTRIES_SUBCATEGORIES: Record<IndustryCategory, Partial<Record<IndustrySubCategory, string>>> = {
  [IndustryCategory.AGRICULTURE]: {
    [IndustrySubCategory.DAIRY]: 'Dairy',
    [IndustrySubCategory.FARMING]: 'Farming',
    [IndustrySubCategory.FISHERY]: 'Fishery',
    [IndustrySubCategory.RANCHING]: 'Ranching',
  },
  [IndustryCategory.ART_AND_ENTERTAINMENT]: {
    [IndustrySubCategory.ART]: 'Art',
    [IndustrySubCategory.BOOKS_AND_LITERATURE]: 'Books & Literature',
    [IndustrySubCategory.DIGITAL_CONTENT]: 'Digital content',
    [IndustrySubCategory.GIFTS]: 'Gifts',
    [IndustrySubCategory.EVENTS_AND_ATTRACTIONS]: 'Events & Attractions',
    [IndustrySubCategory.MUSIC]: 'Music',
    [IndustrySubCategory.PERFORMING_ARTS_AND_SPECTATOR_SPORTS]: 'Performing arts & Spectator sports',
    [IndustrySubCategory.PHOTOGRAPHY]: 'Photography',
    [IndustrySubCategory.RECREATIONAL_FACILITIES]: 'Recreational facilities',
  },
  [IndustryCategory.AUTOMOTIVE]: {
    [IndustrySubCategory.AUTO_BUYING_AND_SELLING]: 'Auto buying and selling',
    [IndustrySubCategory.AUTO_PARTS_AND_TIRES]: 'Auto parts & Tires',
    [IndustrySubCategory.AUTO_SERVICES]: 'Auto services',
  },
  [IndustryCategory.BABY]: {
    [IndustrySubCategory.BABY_CLOTHING]: 'Baby clothing',
    [IndustrySubCategory.BABY_PRODUCTS]: 'Baby products',
    [IndustrySubCategory.SAFETY_AND_HEALTH]: 'Safety & Health',
  },
  [IndustryCategory.BEAUTY]: {
    [IndustrySubCategory.BATH_AND_BODY]: 'Bath & Body',
    [IndustrySubCategory.HAIR_BEARD_AND_NAIL_CARE]: 'Hair, beard & nail care',
    [IndustrySubCategory.MAKEUP_COSMETICS_AND_PERFUMES]: 'Makeup, cosmetics and perfumes',
  },
  [IndustryCategory.CONSTRUCTION]: {
    [IndustrySubCategory.BUILDING_MATERIALS]: 'Building materials',
    [IndustrySubCategory.CIVIL_ENGINEERING]: 'Civil engineering',
    [IndustrySubCategory.GENERAL_CONSTRUCTORS]: 'General constructors',
  },
  [IndustryCategory.CONSUMER_GOODS]: {
    [IndustrySubCategory.CONSUMER_SERVICES]: 'Consumer services',
    [IndustrySubCategory.HOUSEHOLD_GOODS]: 'Household goods',
    [IndustrySubCategory.LUXURY_GOODS_AND_JEWELRY]: 'Luxury goods & Jewelry',
    [IndustrySubCategory.TOBACCO]: 'Tobacco',
  },
  [IndustryCategory.DESIGN]: {
    [IndustrySubCategory.ARCHITECTURE_AND_PLANNING]: 'Architecture & Planning',
    [IndustrySubCategory.GRAPHIC_DESIGN]: 'Graphic design',
    [IndustrySubCategory.INDUSTRIAL_DESIGN]: 'Industrial design',
  },
  [IndustryCategory.ELECTRONICS]: {
    [IndustrySubCategory.COMPUTERS_LAPTOPS_AND_ACCESSORIES]: 'Computers, laptops and accessories',
    [IndustrySubCategory.MAINTENANCE_AND_REPAIR_SERVICES]: 'Maintenance and repair services',
    [IndustrySubCategory.SMARTPHONES_SMARTWATCHES_AND_ACCESSORIES]: 'Smartphones, smartwatches and accessories',
    [IndustrySubCategory.SOUND_SYSTEMS]: 'Sound systems',
  },
  [IndustryCategory.EDUCATION]: {
    [IndustrySubCategory.COLLEGE_AND_UNIVERSITIES]: 'College and Universities',
    [IndustrySubCategory.EARLY_PRIMARY_AND_SECONDARY_EDUCATION]: 'Early, primary and secondary education',
    [IndustrySubCategory.ONLINE_COURSES]: 'Online courses',
    [IndustrySubCategory.PROFESSIONAL_TRAINING_AND_COACHING]: 'Professional training & Coaching',
    [IndustrySubCategory.STUDIOS_AND_VOCATIONAL_SCHOOLS]: 'Studios & Vocational schools',
  },
  [IndustryCategory.ENERGY_AND_MINING]: {
    [IndustrySubCategory.MINING_AND_METALS]: 'Mining & Metals',
    [IndustrySubCategory.OIL_GAS_AND_ENERGY]: 'Oil, Gas and Energy',
    [IndustrySubCategory.UTILITIES]: 'Utilities',
  },
  [IndustryCategory.FINANCE]: {
    [IndustrySubCategory.BANKING_AND_CREDIT_CARDS]: 'Banking & Credit Cards',
    [IndustrySubCategory.CAPITAL_MARKETS]: 'Capital markets',
    [IndustrySubCategory.CRYPTO]: 'Crypto',
    [IndustrySubCategory.FUNDS_AND_TRUSTS]: 'Funds & Trusts',
    [IndustrySubCategory.INSURANCE]: 'Insurance',
    [IndustrySubCategory.INVESTMENT]: 'Investment',
    [IndustrySubCategory.MORTGAGES_AND_LOANS]: 'Mortgages & Loans',
  },
  [IndustryCategory.FOOD_AND_DRINK]: {
    [IndustrySubCategory.ALCOHOLIC_BEVERAGES]: 'Alcoholic beverages',
    [IndustrySubCategory.COFFEE_TEA_CHOCOLATE_AND_GOURMET_FOOD]: 'Coffee, tea, chocolate and gourmet food',
    [IndustrySubCategory.GROCERY_STORES]: 'Grocery stores',
    [IndustrySubCategory.NON_ALCOHOLIC_BEVERAGES]: 'Non-alcoholic beverages',
    [IndustrySubCategory.RESTAURANTS_AND_BARS]: 'Restaurants & Bars',
    [IndustrySubCategory.VEGETARIAN_VEGAN_AND_HEALTHY_EATING]: 'Vegetarian, vegan and healthy eating',
  },
  [IndustryCategory.GAMING]: {
    [IndustrySubCategory.CONSOLE_AND_PC_GAMES]: 'Console & PC games',
    [IndustrySubCategory.EDUCATIONAL_VIDEO_GAMES]: 'Educational video games',
    [IndustrySubCategory.ESPORTS]: 'eSports',
    [IndustrySubCategory.GAMBLING]: 'Gambling',
    [IndustrySubCategory.ONLINE_GAMES]: 'Online games',
    [IndustrySubCategory.TOYS_AND_GAMES]: 'Toys & Games',
  },
  [IndustryCategory.HEALTH_AND_WELLNESS]: {
    [IndustrySubCategory.ALTERNATIVE_MEDICINE]: 'Alternative medicine',
    [IndustrySubCategory.CBD_PRODUCTS]: 'CBD products',
    [IndustrySubCategory.CHILDRENS_HEALTH]: "Children's health",
    [IndustrySubCategory.DENTAL_CARE]: 'Dental care',
    [IndustrySubCategory.HEALTH_CARE_FACILITIES]: 'Health care facilities',
    [IndustrySubCategory.MEDICAL_CARE]: 'Medical care',
    [IndustrySubCategory.MEDICAL_EQUIPMENT_AND_SUPPLIES]: 'Medical equipment and supplies',
    [IndustrySubCategory.MENTAL_HEALTH_CARE]: 'Mental health care',
    [IndustrySubCategory.NUTRITION_AND_WEIGHT_LOSS]: 'Nutrition & Weight Loss',
    [IndustrySubCategory.PHARMACEUTICALS]: 'Pharmaceuticals',
    [IndustrySubCategory.SENIOR_HEALTH]: 'Senior Health',
    [IndustrySubCategory.VISION_CARE]: 'Vision care',
    [IndustrySubCategory.VITAMINS_AND_SUPPLEMENTS]: 'Vitamins & supplements',
    [IndustrySubCategory.WELLNESS_AND_HEALTHY_LIVING]: 'Wellness & healthy living',
  },
  [IndustryCategory.HOME_AND_GARDEN]: {
    [IndustrySubCategory.CLEANING_SERVICES]: 'Cleaning services',
    [IndustrySubCategory.ENERGY_EFFICIENCY]: 'Energy efficiency',
    [IndustrySubCategory.FURNITURE]: 'Furniture',
    [IndustrySubCategory.GARDENING_AND_SUPPLIES]: 'Gardening and supplies',
    [IndustrySubCategory.HOME_DECOR]: 'Home decor',
    [IndustrySubCategory.HOME_ELECTRONICS]: 'Home electronics',
    [IndustrySubCategory.HOME_IMPROVEMENT]: 'Home improvement',
    [IndustrySubCategory.HOME_SECURITY]: 'Home security',
    [IndustrySubCategory.LOCKSMITH]: 'Locksmith',
    [IndustrySubCategory.MOVING]: 'Moving',
  },
  [IndustryCategory.LOGISTICS]: {
    [IndustrySubCategory.IMPORT_AND_EXPORT]: 'Import & Export',
    [IndustrySubCategory.LOGISTICS_AND_SUPPLY_CHAIN]: 'Logistics & Supply chain',
    [IndustrySubCategory.MARITIME]: 'Maritime',
    [IndustrySubCategory.PACKAGE_AND_FREIGHT_DELIVERY]: 'Package & Freight delivery',
    [IndustrySubCategory.TRANSPORTATION_AND_TRUCKING]: 'Transportation & Trucking',
    [IndustrySubCategory.WAREHOUSING]: 'Warehousing',
  },
  [IndustryCategory.MANUFACTURING]: {
    [IndustrySubCategory.AVIATION_AND_AEROSPACE]: 'Aviation& Aerospace',
    [IndustrySubCategory.CHEMICALS]: 'Chemicals',
    [IndustrySubCategory.DEFENSE_AND_SPACE]: 'Defense & Space',
    [IndustrySubCategory.ELECTRICAL_AND_ELECTRONIC_MANUFACTURING]: 'Electrical & Electronic manufacturing',
    [IndustrySubCategory.FOOD_PRODUCTION]: 'Food production',
    [IndustrySubCategory.GLASS_CERAMICS_AND_CONCRETE]: 'Glass, Ceramics & Concrete',
    [IndustrySubCategory.INDUSTRIAL_AUTOMATION]: 'Industrial automation',
    [IndustrySubCategory.MACHINERY]: 'Machinery',
    [IndustrySubCategory.MECHANICAL_OR_INDUSTRIAL_ENGINEERING]: 'Mechanical or Industrial engineering',
    [IndustrySubCategory.PACKAGING_AND_CONTAINERS]: 'Packaging & Containers',
    [IndustrySubCategory.PAPER_AND_FOREST_PRODUCTS]: 'Paper & Forest products',
    [IndustrySubCategory.PLASTICS]: 'Plastics',
    [IndustrySubCategory.RAILROAD_MANUFACTURE]: 'Railroad manufacture',
    [IndustrySubCategory.RENEWABLES_AND_ENVIRONMENT]: 'Renewables & Environment',
    [IndustrySubCategory.SHIPBUILDING]: 'Shipbuilding',
  },
  [IndustryCategory.MEDIA_AND_COMMUNICATIONS]: {
    [IndustrySubCategory.MARKET_RESEARCH]: 'Market research',
    [IndustrySubCategory.NEWSPAPERS]: 'Newspapers',
    [IndustrySubCategory.ONLINE_MEDIA]: 'Online media',
    [IndustrySubCategory.PRINTING]: 'Printing',
    [IndustrySubCategory.PUBLIC_RELATIONS_AND_COMMUNICATIONS]: 'Public relations & Communications',
    [IndustrySubCategory.PUBLISHING]: 'Publishing',
    [IndustrySubCategory.TRANSLATION_AND_LOCALIZATION]: 'Translation & Localization',
    [IndustrySubCategory.WRITING_AND_EDITING]: 'Writing & Editing',
  },
  [IndustryCategory.NONPROFIT_AND_GOVERNMENT]: {
    [IndustrySubCategory.CHARITY]: 'Charity',
    [IndustrySubCategory.GOVERNMENT_SERVICES]: 'Government services',
    [IndustrySubCategory.POLITICAL_OR_RELIGIOUS_NONPROFIT]: 'Political or religious nonprofit',
  },
  [IndustryCategory.PETS]: {
    [IndustrySubCategory.MEDICATION_AND_SUPPLEMENTS]: 'Medication and supplements',
    [IndustrySubCategory.PET_SHOPS_FOOD_AND_SUPPLIES]: 'Pet shops, food, and supplies',
    [IndustrySubCategory.VETERINARY_SERVICES]: 'Veterinary services',
  },
  [IndustryCategory.PROFESSIONAL_SERVICES]: {
    [IndustrySubCategory.ATTORNEYS_AND_LAWYERS]: 'Attorneys & lawyers',
    [IndustrySubCategory.CAREERS]: 'Careers',
    [IndustrySubCategory.CONSULTING_SERVICES]: 'Consulting Services',
    [IndustrySubCategory.EVENTS_PLANNING]: 'Events planning',
    [IndustrySubCategory.MARKETING_AGENCIES]: 'Marketing agencies',
    [IndustrySubCategory.TAX_AND_ACCOUNTING]: 'Tax and accounting',
    [IndustrySubCategory.WEB_DEVELOPMENT_AND_DESIGN]: 'Web development & design',
  },
  [IndustryCategory.REAL_ESTATE]: {
    [IndustrySubCategory.COWORKING_SPACES]: 'Co-working spaces',
    [IndustrySubCategory.LEASING_NON_RESIDENTIAL_REAL_ESTATE]: 'Leasing Non-residential Real Estate',
    [IndustrySubCategory.LEASING_RESIDENTIAL_REAL_ESTATE]: 'Leasing Residential Real Estate',
    [IndustrySubCategory.LUXURIOUS_PROPERTIES]: 'Luxurious properties',
    [IndustrySubCategory.OFFICE_PROPERTY]: 'Office property',
    [IndustrySubCategory.PROPERTY_MANAGEMENT]: 'Property management',
    [IndustrySubCategory.REAL_ESTATE_AGENTS_AND_BROKERS]: 'Real Estate Agents and Brokers',
    [IndustrySubCategory.REAL_ESTATE_BUYING_AND_SELLING]: 'Real Estate Buying and Selling',
    [IndustrySubCategory.REAL_ESTATE_RENTING_AND_LEASING]: 'Real Estate Renting and Leasing',
  },
  [IndustryCategory.RETAIL]: {
    [IndustrySubCategory.DEPARTMENT_STORES]: 'Department Stores',
    [IndustrySubCategory.WHOLESALE]: 'Wholesale',
  },
  [IndustryCategory.SOCIAL_AND_COMMUNITY]: {
    [IndustrySubCategory.COMMUNITY_ORGANIZATIONS]: 'Community organizations',
    [IndustrySubCategory.DATING]: 'Dating',
    [IndustrySubCategory.NETWORKING]: 'Networking',
    [IndustrySubCategory.PARENTING]: 'Parenting',
  },
  [IndustryCategory.SPORTS_OUTDOORS_AND_FITNESS]: {
    [IndustrySubCategory.CAMPING_AND_OUTDOORS]: 'Camping and outdoors',
    [IndustrySubCategory.EXERCISE_AND_FITNESS]: 'Exercise and fitness',
    [IndustrySubCategory.SPORTS_GAMES_AND_TOYS]: 'Sports games and toys',
    [IndustrySubCategory.SPORTING_EQUIPMENT]: 'Sporting equipment',
  },
  [IndustryCategory.STYLE_AND_FASHION]: {
    [IndustrySubCategory.ACCESSORIES]: 'Accessories',
    [IndustrySubCategory.CLOTHING_AND_SHOES]: 'Clothing and shoes',
    [IndustrySubCategory.FASHION_JEWELRY]: 'Fashion Jewelry',
    [IndustrySubCategory.FINE_JEWELRY]: 'Fine Jewelry',
    [IndustrySubCategory.HANDBAGS_WALLETS_HATS_AND_SCARVES]: 'Handbags, wallets, hats and scarves',
    [IndustrySubCategory.WATCHES_AND_SUNGLASSES]: 'Watches & Sunglasses',
  },
  [IndustryCategory.TECHNOLOGY]: {
    [IndustrySubCategory.AUTOMOTIVE]: 'Automotive',
    [IndustrySubCategory.BIOTECH]: 'Biotech',
    [IndustrySubCategory.CLEANTECH]: 'CleanTech',
    [IndustrySubCategory.CRM]: 'CRM',
    [IndustrySubCategory.CYBERSECURITY]: 'Cybersecurity',
    [IndustrySubCategory.ECOMMERCE_SERVICES]: 'Ecommerce services',
    [IndustrySubCategory.EDTECH]: 'Edtech',
    [IndustrySubCategory.FINTECH]: 'Fintech',
    [IndustrySubCategory.HEALTHTECH]: 'Healthtech',
    [IndustrySubCategory.HRTECH]: 'HRtech',
    [IndustrySubCategory.LEGALTECH]: 'Legaltech',
    [IndustrySubCategory.MARKETPLACE]: 'Marketplace',
    [IndustrySubCategory.MARTECH]: 'Martech',
    [IndustrySubCategory.PAYTECH]: 'Paytech',
    [IndustrySubCategory.REGTECH]: 'Regtech',
    [IndustrySubCategory.REMOTE_WORK]: 'Remote work',
    [IndustrySubCategory.SAAS]: 'SAAS',
    [IndustrySubCategory.TELECOM]: 'Telecom',
    [IndustrySubCategory.PROPTECH]: 'Proptech',
  },
  [IndustryCategory.TRAVEL]: {
    [IndustrySubCategory.AUTO_RENTALS]: 'Auto rentals',
    [IndustrySubCategory.CRUISES]: 'Cruises',
    [IndustrySubCategory.FLIGHTS]: 'Flights',
    [IndustrySubCategory.HOTELS_AND_ACCOMMODATIONS]: 'Hotels and accommodations',
    [IndustrySubCategory.RAIL_TRANSPORTATION]: 'Rail transportation',
    [IndustrySubCategory.TRANSPORTATION_SERVICES]: 'Transportation services',
    [IndustrySubCategory.TRAVEL_AGENCY_AND_TOURS]: 'Travel agency & Tours',
  },
};
