import React, { useCallback } from 'react';
import classNames from 'classnames';
import Container from '@material-ui/core/Container';
import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import { DialogProps } from './types';
import DialogActions from './components/DialogActions';
import DialogTitle from './components/DialogTitle';
import DialogFullScreenTitle from './components/DialogFullScreenTitle';

import useStyles from './style';

const Dialog: React.FC<DialogProps> = (props) => {
  const classes = useStyles(props);

  const {
    isOpen,
    title,
    subtitle,
    onClose,
    fullScreen,
    fullWidth,
    actions,
    closeButtonProps,
    containerMaxWidth = false,
    hideDialogTitle = false,
    backdropProps = {},
    dialogRef,
    children,
  } = props;

  const onCLoseHandler = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <MuiDialog
      open={isOpen}
      onClose={onCLoseHandler}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={containerMaxWidth}
      scroll="body"
      BackdropProps={backdropProps}
      ref={dialogRef}
      classes={{
        root: classNames(classes.root, { [classes.fullScreen]: fullScreen }),
        paper: classes.dialogPaper,
      }}
    >
      {!hideDialogTitle && (
        <MuiDialogTitle disableTypography classes={{ root: classes.dialogTitleRoot }}>
          {fullScreen && (
            <DialogFullScreenTitle
              title={title}
              subtitle={subtitle}
              onClose={onCLoseHandler}
              closeButtonProps={closeButtonProps}
              actions={actions}
              classes={{
                toolbarRoot: classes.toolbarRoot,
                titlesWrapper: classes.titlesWrapper,
                title: classes.title,
                subtitle: classes.subtitle,
              }}
            />
          )}
          {!fullScreen && (
            <DialogTitle
              title={title}
              subtitle={subtitle}
              onClose={onCLoseHandler}
              closeButtonProps={closeButtonProps}
            />
          )}
        </MuiDialogTitle>
      )}

      <DialogContent classes={{ root: classes.dialogContent }}>
        <Container maxWidth={containerMaxWidth}>
          {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
          <>{children}</>
        </Container>
      </DialogContent>

      {!!actions && !fullScreen && <DialogActions actions={actions} classes={{ root: classes.dialogActions }} />}
    </MuiDialog>
  );
};

export default Dialog;
