import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { CalloutMessageProps } from './types';
import { getGradientColor } from '../../../app/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: (props: CalloutMessageProps) => ({
      borderRadius: '1rem',
      margin: props.margin ? '12px 0' : 0,
      width: '100%',
      '&.MuiAlert-standardInfo': {
        backgroundColor: getGradientColor('blue', 10),
        '& .MuiAlert-icon .MuiSvgIcon-root': {
          color: getGradientColor('blue', 90),
        },
        '& .MuiAlertTitle-root': {
          color: getGradientColor('blue', 90),
        },
        '& .MuiAlert-message': {
          color: getGradientColor('blue', 90),
        },
        '& .MuiButton-textPrimary': {
          color: getGradientColor('blue', 90),
        },
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
    }),
    title: {
      fontWeight: 'bold',
    },
    icon: {},
    message: {
      fontWeight: 'normal',
      whiteSpace: 'normal',
    },
    action: {
      whiteSpace: 'nowrap',
    },
  }),
);

export default useStyles;
