import { getFromLocalStorage, removeFromLocalStorage } from '../../../fe_common/client/services/utils';

export const getCpanelVersionFromCache = (): null | any | undefined => getFromLocalStorage('_mayple_client_version');

export const clearCpanelVersionCache = (): void => {
  removeFromLocalStorage('_mayple_client_version');
};

export const forceRefreshPage = (): void => {
  window.location.reload();
};
