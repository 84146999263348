import React, { ReactElement, ReactNode } from 'react';
import MuiCard, { CardProps as MuiCardProps } from '@material-ui/core/Card';
import CardHeader, { CardHeaderProps } from '@material-ui/core/CardHeader';
import CardContent, { CardContentProps } from '@material-ui/core/CardContent';
import CardActions, { CardActionsProps } from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';

import { useCardActionsStyles, useCardContentStyles, useCardStyles } from './style';

interface CardProps extends MuiCardProps {
  children: ReactNode;
  classes?: Record<string, string>;
}

/*
 * Inspired by https://www.patterns.dev/posts/compound-pattern/
 *
 * Supported props:
 * https://v4.mui.com/api/card/
 * */
function Card(props: CardProps): ReactElement {
  const classes = useCardStyles(props);

  const { children, variant = 'outlined', onClick, ...rest } = props;

  return (
    <MuiCard onClick={onClick} variant={variant} classes={{ root: classes.root }} {...(rest || {})}>
      {!!onClick && <CardActionArea classes={{ root: classes.cardActionAreaRoot }}>{children}</CardActionArea>}
      {!onClick && children}
    </MuiCard>
  );
}

interface ContentProps extends CardContentProps {
  children: ReactNode;
  classes?: Record<string, string>;
}

/**
 * Supported props:
 * https://v4.mui.com/api/card-content/
 */
function Content(props: ContentProps) {
  const classes = useCardContentStyles(props);

  return <CardContent classes={{ root: classes.root }}>{props.children}</CardContent>;
}

interface ActionProps extends CardActionsProps {
  children: ReactNode;
}

/**
 * Supported props:
 * https://v4.mui.com/api/card-actions/
 */
function Actions(props: ActionProps) {
  const classes = useCardActionsStyles(props);

  return <CardActions classes={{ root: classes.root }}>{props.children}</CardActions>;
}
/**
 * Supported props:
 * https://v4.mui.com/api/card-header/
 */
const Header: React.FC<CardHeaderProps> = (props) => <CardHeader {...props} />;

Card.Actions = Actions;

Card.Header = Header;

Card.Content = Content;

export default Card;
