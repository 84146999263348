import { useMemo } from 'react';
import { parse, ParsedQuery, ParseOptions } from 'query-string';

interface POptions extends ParseOptions {
  shouldCleanUrl?: boolean;
}

interface Options {
  searchString?: string;
  options?: POptions;
}

const useQueryString = (opts: Options = {}): ParsedQuery => {
  const { searchString = window.location.search, options = {} } = opts;
  const { /* shouldCleanUrl = false, */ ...rest } = options;

  const result = useMemo(() => parse(searchString, rest), [searchString, rest]);

  // useEffect(() => {
  //   if (shouldCleanUrl) {
  //     window.history.replaceState({}, document.title, window.location.pathname);
  //   }
  // }, [shouldCleanUrl]);

  return result;
};

export default useQueryString;
