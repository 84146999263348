import React, { isValidElement } from 'react';
import isURL from 'validator/lib/isURL';

import { TextIcon } from '../TextIcon';

export type SimpleIconType = React.ReactNode | React.FC | string;

interface SimpleIconProps {
  icon: SimpleIconType;
  style?: Record<string, any>;
  className?: string;
}

const SimpleIcon: React.FC<SimpleIconProps> = (props) => {
  const { icon, style, className } = props;

  if (!icon) {
    return null;
  }

  if (icon && typeof icon === 'string') {
    // checking if this is a url string - then returning img
    if (isURL(icon)) {
      return <img src={icon} className={className} style={style} alt="" />;
    }
    // Otherwise - this is probably a MUI/FA based icon
    return <TextIcon icon={icon} className={className} style={style} />;
  }

  // We can pass an pre rendered Icon component - in that case no classes are applied
  // and the icon should be controlled from outside the SelectionCard component
  if (isValidElement<React.ReactNode>(icon)) {
    return icon;
  }

  // In this case we are just passing the SVGIcon component name, and we can apply the classes
  return React.createElement(icon as any, { className, style });
};

export default SimpleIcon;
