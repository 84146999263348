import React, { FC } from 'react';
import MuiButton from '@material-ui/core/Button';

import { ButtonProps } from './types';
import { RefreshSpinner } from '../../atoms';

const Button: FC<ButtonProps> = ({ loading, label, raised, variant, link, href, startIcon, ...rest }) => (
  <MuiButton
    {...rest}
    variant={raised ? 'contained' : variant} // For backward compatibility with older SimpleButton component
    href={href || link} // For backward compatibility with older SimpleButton component
    startIcon={loading ? <RefreshSpinner size={24} /> : startIcon}
  >
    {label}
  </MuiButton>
);

export default Button;
