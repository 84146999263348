import React from 'react';
import { PropertyFormatterType } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const defaultFormatter: PropertyFormatterType = (value: any) => value;

export const formatListOfEntities = (
  values: Record<string, any>,
  formatter = defaultFormatter,
  notFoundMessage = 'Not found',
): React.ReactElement | string => {
  if (!values || !Array.isArray(values) || values.length === 0) {
    return notFoundMessage;
  }

  const retVal = values.map((item) => formatter(item));

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{retVal}</>;
};

export const wrapWithSpan = (formatter: PropertyFormatterType, spanProps = {}): PropertyFormatterType => {
  // console.log('wrapWithDiv func', formatter);
  const randKey = Math.random().toString(36).substring(2, 5);

  return function wrappedValue(value) {
    return (
      <span key={`wrap-with-span-${randKey}`} {...spanProps}>
        {formatter(value)}
      </span>
    );
  };
};
