import React, { FC } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';

import { PropertiesListProps, PropertiesMetaType } from './types';
import { TextDisplay } from '../index';

import useStyles from './style';

/**
 * This is a default formatter in case no formatter was passed, it just returns the value
 * @param value
 * @returns {*}
 */
const defaultFormatter = (value: any): any => value;

const PropertiesList: FC<PropertiesListProps> = (props) => {
  const { id, data, propertiesMeta } = props;
  let currGroup = '';
  let showGroup = false;

  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {propertiesMeta.map((meta: PropertiesMetaType) => {
        const {
          group = '',
          label,
          path,
          formatter = defaultFormatter,
          defaultValue = null,
          customClass,
          customComponent: CustomComponent = null,
          hidden,
          layout,
        } = meta;

        if (group !== '' && currGroup !== group) {
          currGroup = group;
          showGroup = true;
        } else {
          showGroup = false;
        }

        const value = get(data, path, defaultValue);
        const formattedValue = formatter(value, defaultValue, data) ?? defaultValue;
        const additionalClasses = typeof customClass === 'function' ? customClass(value) : customClass || '';

        return (
          <React.Fragment key={`properties-list-collection-${id}-${path}-${currGroup}`}>
            {showGroup && (
              <div className={classes.groupWrapper} key={`properties-group-${currGroup}`}>
                <TextDisplay variant="subtitle1" className={classes.groupLabel}>
                  {currGroup}
                </TextDisplay>
              </div>
            )}

            {formattedValue && !hidden && (
              <div
                className={classNames(classes.propertyWrapper, {
                  [classes.verticalLayout]: layout === 'vertical',
                })}
                key={`properties-list-${id}-${path}`}
              >
                <TextDisplay variant="subtitle1" className={classes.propertyLabel}>
                  {label}
                </TextDisplay>
                {!CustomComponent && (
                  <TextDisplay variant="subtitle1" className={classNames(classes.propertyValue, additionalClasses)}>
                    {formattedValue}
                  </TextDisplay>
                )}
                {CustomComponent && (
                  // @ts-ignore
                  <CustomComponent value={formattedValue} className={additionalClasses} />
                )}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default PropertiesList;
