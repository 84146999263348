import minilog from 'minilog';

// Create a logger
minilog.enable();
const logInstance = minilog('client');

// Track error, usually to Sentry.io
function trackError(/* error */) {
  // TODO: Add error tracking here
  // window.Rollbar.error(error);
}

// Monkey patch the existing error function, adding error tracking
const existingErrorFunc = logInstance.error.bind(logInstance);
function newErrorFunc(error) {
  trackError(error);
  existingErrorFunc(error);
}
newErrorFunc.bind(logInstance);
logInstance.error = newErrorFunc;

export const logger = logInstance;
export const clientLogger = logInstance;

export function handleClientError(error, message) {
  // No error object?
  if (!error) {
    const string = message.toLowerCase();
    const substring = 'script error';
    if (string.indexOf(substring) > -1) {
      clientLogger.error('Script Error: see browser console for more details');
    } else {
      clientLogger.error(`Uncaught exception with null error object. Message: ${message}`);
    }
    return;
  } else {
    clientLogger.error(`${message}. Exception follows.`);
  }

  // Log it
  clientLogger.error(error);
}
