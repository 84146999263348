import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { colors } from '../../../../fe_common/client/app/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
    },
    errorContainer: {
      maxWidth: 1096,
      marginTop: 72,
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('sm')]: {
        marginTop: 40,
        flexDirection: 'column-reverse',
        justifyContent: 'center',
        textAlign: 'center',
        maxWidth: 488,
      },
    },
    errorMessageContainer: {
      paddingTop: 24,
    },
    errorImageContainer: {
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    errorDetailsContainer: {
      background: 'transparent',
      marginTop: 16,
      '&::before': {
        opacity: 0,
      },
    },
    errorDetailsSummary: {
      padding: 0,
    },
    errorDetails: {
      padding: 16,
      backgroundColor: colors.white_darker,
      border: `1px solid ${colors.black_lightest}`,
      boxSizing: 'border-box',
      borderRadius: 8,
      marginTop: 1,
      wordBreak: 'break-word',

      '& pre': {
        whiteSpace: 'normal',
        fontSize: '16px',
        lineHeight: '28px',
        margin: 0,
        color: colors.black,
      },
    },
    errorMessage: {
      color: colors.red,
    },
    errorCat: {
      maxHeight: 450,
      [theme.breakpoints.down('sm')]: {
        maxHeight: 150,
      },
    },
    goToHomepageButton: {
      marginTop: 48,
    },
  }),
);

export default useStyles;
