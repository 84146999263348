import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'grid',
    [theme.breakpoints.up('xl')]: {
      padding: '0px 120px',
      margin: '0 auto',
      maxWidth: 1920,
    },
    [theme.breakpoints.only('lg')]: {
      padding: '0px 32px',
      margin: '0 auto',
      maxWidth: 1280,
    },
    [theme.breakpoints.only('md')]: {
      padding: '0px 32px',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '0px 24px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 16px',
    },
  },
});

/**
 * This component should be used as our design standard padding for all pages
 * @param children
 * @param classes
 * @param noPadding
 * @returns {*}
 * @constructor
 */
function ContainerWrapped({ children, classes, noPadding, ...rest }) {
  return (
    <div className={noPadding ? '' : classes.container} {...rest}>
      {children}
    </div>
  );
}

ContainerWrapped.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
};
ContainerWrapped.defaultProps = {
  noPadding: false,
};

export const Container = withStyles(styles)(ContainerWrapped);
