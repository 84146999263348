import { useQuery } from '@apollo/react-hooks';
import { useCallback, useEffect, useState } from 'react';
import { FilterRequest } from '@mayple/types';
import { ApolloError } from 'apollo-client';
import { QueryResult } from '@apollo/react-common';
import get from 'lodash/get';

interface Options {
  pageSize: number;
  variables: Record<string, any>;
  uniqueKey: string;
  descending: boolean;
  sortBy: string;
  maxResults: number;
  filter: FilterRequest | null;
  skip?: boolean;
}

interface Return<T> {
  loading: boolean;
  data: T[];
  error?: ApolloError;
  originalQuery: QueryResult;
  refetch: () => void;
}

const defaultOptions: Options = {
  pageSize: 1000,
  variables: {},
  uniqueKey: '',
  descending: true,
  sortBy: 'id',
  maxResults: 10000,
  filter: null,
};

/**
 * This custom hook will run the requested query in batches of {pageSize} items per request.
 * The aggregated data will be passed to the rendered component.
 *
 * --- NOTE ---
 * This should be a temporary solution following the ticket https://perfpie.atlassian.net/browse/DEV-4675
 * As we currently (05.10.20) cannot run queries of 2000+ items per query
 * ------------
 */
function useQueryPagination<T>(
  queryClass: Record<string, any>,
  queryEntityKey: string,
  options?: Partial<Options>,
): Return<T> {
  const [pageNumber, setPageNumber] = useState<number>(0);

  const [loadingData, setLoadingData] = useState<boolean>(true);

  const [aggregatedData, setAggregatedData] = useState<T[]>([]);

  const optionsCombined = { ...defaultOptions, ...options };

  const { uniqueKey, variables, pageSize, filter, descending, sortBy, maxResults, skip = false } = optionsCombined;

  const originalQuery = useQuery(queryClass.query, {
    variables: {
      ...variables,
      pagination: {
        pageSize,
        pageNumber,
        by: [{ desc: descending, key: sortBy }],
      },
      filter,
    },
    notifyOnNetworkStatusChange: true,
    skip,
  });

  const { loading: loadingQuery, error, data } = originalQuery;

  useEffect(() => {
    const queryData = get(data, queryEntityKey);

    if (!loadingQuery && queryData && queryData.length > 0) {
      setAggregatedData((prevAggregatedData) => {
        if (uniqueKey !== '') {
          // To prevent duplications (especially when using Hot-loader in development)
          return prevAggregatedData.concat(
            // @ts-ignore
            queryData.filter((item: T) => !prevAggregatedData.find((f) => f[uniqueKey] === item[uniqueKey])),
          );
        }

        return [...prevAggregatedData, ...queryData];
      });
      // In case we want to stop pagination after maxResults of data.
      const shouldStopQuery = aggregatedData.length + pageSize >= maxResults;

      // If the result of last pagination query is less then page size. we know not to as the next page
      // Or if the length is more than page size, then the  pagination has failed.
      if (queryData.length !== pageSize || shouldStopQuery) {
        setLoadingData(false);
      } else {
        // Ask the next page query
        setPageNumber((prev) => prev + 1);
      }
    } else if (!loadingQuery) {
      // If the query data length is 0, we finished loading
      setLoadingData(false);
    }

    // This is complaining about the lack of aggregatedData.length dep. there is no need to add it here as it cant be
    // dependant about it and also update aggregatedData in the same effect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, queryEntityKey, loadingQuery, pageSize, uniqueKey, maxResults]);

  const refetch = useCallback(() => {
    setAggregatedData([]);
    setPageNumber(0);
    originalQuery.refetch();
  }, [originalQuery]);

  return {
    loading: loadingData,
    data: aggregatedData,
    error,
    originalQuery,
    refetch,
  };
}

export default useQueryPagination;
