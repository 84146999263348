import { useEffect } from 'react';
import { AccountType, Company, InternalTeam, Marketer, Project, User } from '@mayple/types';

import { useCounter } from '../hooks';

export const reportEvent = (
  category: string,
  action: string,
  label?: string | string[],
  traits: Record<string, unknown> = {},
): void => {
  window.mayple_analytics.track(`${category} ${action}`, {
    category,
    action,
    label,
    ...traits,
  });
};

export const reportTabClick = (pageName: string, tabName: string, traits: Record<string, unknown> = {}): void => {
  reportEvent('Tab', 'Clicked', `${pageName}.${tabName}`, { tabName, pageName, ...traits });
};

export interface ProjectTraits extends Record<string, any> {
  companyName: string | undefined;
  companyId: number | undefined;
  projectName: string | undefined;
  projectId: number | undefined;
  projectLifeCycleStatus: string | undefined;
}

export const collectTraitsFromProject = (project: Project | null | undefined): ProjectTraits => ({
  companyName: project?.company?.name,
  companyId: project?.companyId,
  projectName: project?.name,
  projectId: project?.id,
  projectLifeCycleStatus: project?.projectLifeCycleStatus,
});

export const collectTraitsFromMarketer = (marketer: Marketer | null | undefined): Record<string, unknown> => ({
  marketerName: marketer?.name,
  marketerId: marketer?.id,
});

export const collectTraitsFromActingAccount = (
  actingAccount: Marketer | Company | InternalTeam | undefined,
  accountType: AccountType | null,
): Record<string, unknown> => ({
  accountId: actingAccount?.id,
  accountType,
});

export const reportOverviewPayoutDateChanged = (eventName: string, traits: Record<string, unknown>): void => {
  window.mayple_analytics.track(eventName, {
    ...traits,
  });
};

export const getPageEventsParameters = (
  pageName: string,
): {
  category: string;
  name: string;
  traits: Record<string, any>;
} => {
  let category = '';
  let name = '';
  let traits = {};

  // if the page name has a dot in it then we are reporting it in the new format
  const dotPosition = (pageName || '').indexOf('.');

  if (dotPosition > -1) {
    try {
      // first we split the pageName into category and name
      category = pageName.slice(0, dotPosition);
      name = pageName.slice(dotPosition + 1);
      traits = {
        pageName,
      };

      // then reporting
    } catch (e) {
      // this might fail - so fall back to old report
      name = pageName || '';
    }
  } else {
    // report in the old format
    name = pageName || '';
  }

  return {
    category,
    name,
    traits,
  };
};

export const reportPageEvent = (pageName = '', additionalTraits: Record<string, any> = {}): void => {
  const { category, name, traits } = getPageEventsParameters(pageName);

  window.mayple_analytics.page(category, name, { ...traits, ...additionalTraits });
};

export const useReportPageEventOnce = (isReady: boolean, pageName = '', traits: Record<string, any> = {}): void => {
  const [pageViewedEventCounter, addViewsCount] = useCounter();

  useEffect(() => {
    if (pageViewedEventCounter >= 1 || !isReady) {
      return;
    }

    reportPageEvent(pageName, traits);

    addViewsCount();
  }, [addViewsCount, isReady, pageName, pageViewedEventCounter, traits]);
};

export const identifyUser = (user: User): void => {
  const {
    id,
    uuid,
    firstName,
    lastName,
    timezone,
    locale,
    displayImageUrl,
    emailAddress,
    maypleUniversalTrackingId,
    created,
  } = user;

  const traits = {
    id,
    uuid,
    email: emailAddress,
    avatar: displayImageUrl,
    firstName,
    lastName,
    locale,
    timezone,
    createdAt: created,
  };

  window.mayple_analytics?.identify?.(maypleUniversalTrackingId, traits);
};
