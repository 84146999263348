import React, { FC } from 'react';
import MuiTypography, { TypographyProps } from '@material-ui/core/Typography';
import classNames from 'classnames';

import useStyles from './style';

export interface TextDisplayProps extends TypographyProps {
  bold?: boolean;
  inline?: boolean;
  italic?: boolean;
  underline?: boolean;
  colorOverride?: string;
  style?: Record<string, any>;
}

const TextDisplay: FC<TextDisplayProps> = (props) => {
  const classes = useStyles(props);
  // extracting colorOverride - so it won't reach the HTML DOM
  // it's in use in the style.ts file
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, bold, inline, italic, underline, colorOverride, ...rest } = props;

  const extraClasses = classNames(classes.root, classes.color, {
    [classes.bold]: bold,
    [classes.inline]: inline,
    [classes.italic]: italic,
    [classes.underline]: underline,
  });

  return (
    <MuiTypography classes={{ root: extraClasses }} {...rest}>
      {children}
    </MuiTypography>
  );
};

export default TextDisplay;
