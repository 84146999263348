import React, { FC } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroupMUI from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';

import { RadioGroupProps } from './types';

import { TextDisplay } from '../../display';

import useStyles from './style';

const RadioGroup: FC<RadioGroupProps> = (props) => {
  const {
    options,
    name,
    label,
    value,
    disabled = false,
    layout = 'horizontal',
    onChange,
    color,
    helperText,
    error,
  } = props;

  const classes = useStyles(props);

  return (
    <FormControl component="fieldset" className={props?.classes?.root || classes.root} disabled={disabled}>
      {label && (
        <FormLabel component="legend">
          <TextDisplay variant="caption">{label}</TextDisplay>
        </FormLabel>
      )}
      <RadioGroupMUI aria-label={label} name={name} value={value} onChange={onChange} row={layout === 'horizontal'}>
        {options.map(({ label: optionLabel, value: optionValue, isDeprecated = false, isDisabled = false }) => {
          if (isDeprecated) {
            return null;
          }
          return (
            <FormControlLabel
              key={`RadioGroup-${optionValue}`}
              checked={value === optionValue}
              value={optionValue}
              control={<Radio disabled={isDisabled} color={color} />}
              label={optionLabel}
            />
          );
        })}
      </RadioGroupMUI>
      {!!helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default RadioGroup;
