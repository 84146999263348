import { useMediaQuery, useTheme } from '@material-ui/core';

type DeviceInfoType = {
  isMobile: boolean;
  isTablet: boolean;
};

const useDeviceInfo = (): DeviceInfoType => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return {
    isMobile,
    isTablet,
  };
};

export default useDeviceInfo;
