import { useEffect, useState } from 'react';

/**
 * This hook is updating the internal debouncedValue in intervals of (wait) and returns the value
 * useful when you want to update redux state on change in the state of the component, without causing performance
 * issues.
 *
 * The idea to use this hook came from here:
 * https://dmitripavlutin.com/controlled-inputs-using-react-hooks/
 *
 * @param value
 * @param wait
 * @returns {unknown}
 */
export const useDebouncedValue = (value, wait) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const id = window.setTimeout(() => {
      setDebouncedValue(value);
    }, wait);
    return () => {
      window.clearTimeout(id);
    };
  }, [value, wait]);

  return debouncedValue;
};
