import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    contentRoot: {
      textAlign: 'center',
      display: 'block',
      padding: '2rem',
    },
  }),
);

export default useStyles;
