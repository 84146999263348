import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const drawerWidth = 256;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
    },
    mainContent: {
      height: '100%',
      display: 'grid',
      minHeight: 'calc(100vh - 64px)',
      transition: 'margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)',
    },
    appBarContent: {
      // display: 'flex',
      // flexDirection: 'row',
      flex: '1 1 auto',
      transition: 'margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)',
    },
    grow: {
      flexGrow: 1,
    },
    drawerPaper: {
      maxWidth: drawerWidth,
      width: '100%',
      flexShrink: 0,
    },
    drawerOpen: {
      marginLeft: drawerWidth,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    toolbarRoot: {
      transition: 'padding-right 450ms cubic-bezier(0.23, 1, 0.32, 1)',
    },
    toolbarRootDrawerOpen: {
      [theme.breakpoints.up('md')]: {
        paddingRight: drawerWidth,
      },
    },
    closeMenuButtonWrapper: {
      padding: '8px 24px',
    },
  }),
);

export default useStyles;
