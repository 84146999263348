import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import LoadingPlaceholder from '../LoadingPlaceholder';
import { colors } from '../../../app/theme';

const useStyles = makeStyles(
  createStyles({
    root: {
      position: 'fixed',
      background: colors.site_background_main,
      zIndex: 1,
    },
    progress: {},
  }),
);

const PageLoadingPlaceholder: React.FC = () => {
  const classes = useStyles();
  return <LoadingPlaceholder classes={classes} />;
};

export default PageLoadingPlaceholder;
