import { AccountType, IdentityDetails } from '@mayple/types';

const getActingAccountTypeFromIdentityDetails = (identityDetails: IdentityDetails | null): AccountType | null => {
  const { actingCompanyId, actingInternalTeamId, actingMarketerId } = (identityDetails || {}) as IdentityDetails;

  if (actingCompanyId) {
    return AccountType.COMPANY;
  }
  if (actingMarketerId) {
    return AccountType.MARKETER;
  }
  if (actingInternalTeamId) {
    return AccountType.INTERNAL_TEAM;
  }

  return null;
};

export default getActingAccountTypeFromIdentityDetails;
