import { useQuery } from '@apollo/react-hooks';
import { ActingCompanyQuery } from 'growl-graphql/dist/queries/ActingCompanyQuery';
import { ActingMarketerQuery } from 'growl-graphql/dist/queries/ActingMarketerQuery';
import { ActingInternalTeamQuery } from 'growl-graphql/dist/queries/ActingInternalTeamQuery';
import { IdentityDetailsQuery } from 'growl-graphql/dist/queries/IdentityDetailsQuery';
import { ViewerUserQuery } from 'growl-graphql/dist/queries/ViewerUserQuery';
import { QueryHookOptions } from '@apollo/react-hooks/lib/types';

const useActingInternalTeam = (skipQuery = false): Record<string, any> => {
  const { data, loading, error, refetch } = useQuery(ActingInternalTeamQuery.query, {
    skip: skipQuery,
    notifyOnNetworkStatusChange: true,
  });
  return {
    actingInternalTeam: data?.actingInternalTeam,
    loadingActingInternalTeam: loading,
    errorActingInternalTeam: error,
    refetchActingInternalTeam: refetch,
  };
};

const useActingCompany = (
  options: QueryHookOptions = {
    skip: false,
    notifyOnNetworkStatusChange: true,
  },
): Record<string, any> => {
  const { data, loading, error, refetch } = useQuery(ActingCompanyQuery.query, options);
  return {
    actingCompany: data?.actingCompany,
    loadingActingCompany: loading,
    errorActingCompany: error,
    refetchActingCompany: refetch,
  };
};

const useActingMarketer = (skipQuery = false): Record<string, any> => {
  const { data, loading, error, refetch } = useQuery(ActingMarketerQuery.query, {
    skip: skipQuery,
    notifyOnNetworkStatusChange: true,
  });
  return {
    actingMarketer: data?.actingMarketer,
    loadingActingMarketer: loading,
    errorActingMarketer: error,
    refetchActingMarketer: refetch,
  };
};

const useIdentityDetails = (): Record<string, any> => {
  const {
    data,
    loading,
    error,
    refetch: refetchIdentityDetails,
  } = useQuery(IdentityDetailsQuery.query, {
    notifyOnNetworkStatusChange: true,
  });
  return {
    identityDetails: data?.identityDetails,
    loadingIdentityDetails: loading,
    errorIdentityDetails: error,
    refetchIdentityDetails,
  };
};

const useViewer = (shouldSkip: boolean): Record<string, any> => {
  const {
    data,
    loading,
    error,
    refetch: refetchViewer,
  } = useQuery(ViewerUserQuery.query, {
    notifyOnNetworkStatusChange: true,
    skip: shouldSkip,
  });
  return {
    viewer: data?.viewer,
    loadingViewer: loading,
    errorViewer: error,
    refetchViewer,
  };
};

export { useIdentityDetails, useActingCompany, useActingMarketer, useActingInternalTeam, useViewer };
