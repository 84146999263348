import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import { TitleWithTooltipProps } from './types';

import TextDisplay from '../TextDisplay';
import SimpleIcon from '../../atoms/SimpleIcon';
import { iconCodes } from '../../../app/icons';

import useStyles from './style';

const TitleWithTooltip: React.FC<TitleWithTooltipProps> = (props) => {
  const classes = useStyles(props);
  const { title, tooltip, variant = 'body1', gutterBottom } = props;

  return (
    <TextDisplay variant={variant} className={classes.title} gutterBottom={gutterBottom}>
      {title}
      {!!tooltip && (
        <Tooltip title={tooltip} arrow classes={{ tooltip: classes.tooltip }}>
          <span>
            <SimpleIcon icon={iconCodes.help} className={classes.tooltipPopper} />
          </span>
        </Tooltip>
      )}
    </TextDisplay>
  );
};

export default TitleWithTooltip;
