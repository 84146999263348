import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';

import { TextDisplay } from '../../display';
import { TextIcon } from '../TextIcon';
import { iconCodes } from '../../../app/icons';
import { logos } from '../../../app/logos';
import { colors } from '../../../app/theme';

export function SelectedEntityViewer(props) {
  // console.log('SelectedEntityViewer props', props);

  const { entity, errorMessage, onClick, icon, selectedText, disabled, addBorder, secondaryText, button } = props;

  if (!entity) {
    return <div>{errorMessage}</div>;
  }
  const { name, displayImageUrl = logos.logoNoTextCherry, email = 'Unknown Name' } = entity;
  const finalName = name || email;

  let style = { maxWidth: 420, paddingLeft: 0 };
  if (addBorder) {
    style = {
      ...style,
      border: '1px solid #C2C2CB',
      borderRadius: 8,
    };
  }

  return (
    <div>
      {selectedText && (
        <div style={{ marginBottom: 8 }}>
          <TextDisplay variant="body1" colorOverride={colors.cherry}>
            {selectedText}
          </TextDisplay>
        </div>
      )}
      <List>
        <ListItem button={button} disabled={disabled} onClick={onClick} style={style}>
          <Avatar src={displayImageUrl} />
          <ListItemText primary={finalName} secondary={secondaryText} />
          {!!icon && <TextIcon size={24} icon={icon} color="disabled" />}
        </ListItem>
      </List>
    </div>
  );
}

SelectedEntityViewer.propTypes = {
  entity: PropTypes.object,
  errorMessage: PropTypes.string,
  icon: PropTypes.string,
  selectedText: PropTypes.string,
  secondaryText: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  addBorder: PropTypes.bool,
  button: PropTypes.bool,
};

SelectedEntityViewer.defaultProps = {
  entity: null,
  secondaryText: null,
  disabled: false,
  button: false,
  addBorder: true,
  errorMessage: 'Error getting entity data',
  selectedText: '',
  icon: iconCodes.close,
  onClick: () => {},
};
