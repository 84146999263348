import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

export const useCardStyles = makeStyles(() =>
  createStyles({
    root: {},
    cardActionAreaRoot: {
      height: '100%',
    },
  }),
);

export const useCardContentStyles = makeStyles(() =>
  createStyles({
    root: {
      '&:last-child': {
        paddingBottom: '1.25rem',
      },
    },
  }),
);

export const useCardActionsStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingBottom: '1rem',
    },
  }),
);
