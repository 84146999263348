import React from 'react';
import PropTypes from 'prop-types';
import { TextDisplay } from '../../display';

export function StatusIndicator({ status, options }) {
  const item = options.find(({ value }) => value === status);
  const { color = '#747474', label = 'Unknown' } = item;

  const style = {
    backgroundColor: color,
    height: 16,
    width: 16,
    marginRight: 8,
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'middle',
  };
  return (
    <div>
      <span style={style} />
      <TextDisplay inline>{label}</TextDisplay>
    </div>
  );
}

StatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};
