import { useEffect } from 'react';
import { DocumentNode } from 'graphql';
import { ApolloError, ApolloClient } from 'apollo-client';
import { ExecutionResult } from '@apollo/react-common';
import { MutationFunctionOptions } from '@apollo/react-common/lib/types/types';
import { useMutation as useApolloMutation } from '@apollo/react-hooks';
import { MutationHookOptions } from '@apollo/react-hooks/lib/types';

import { setEntityOperationResponseNotification, setNotification } from '../services/notification';
import { reportError } from '../services/monitoring';
import { reportEvent } from '../logic/events';

export interface Mutation {
  name: string;
  query: DocumentNode;
}

interface UseMutationOptions {
  mutationResponseKey?: string;
  successMessage?: string;
  mutationOptions?: MutationHookOptions;
}

export interface UseMutation {
  mutate: (options?: MutationFunctionOptions | undefined) => Promise<ExecutionResult<any>>;
  loading: boolean;
  error: ApolloError | undefined;
  data: any;
  called: boolean;
  client: ApolloClient<Record<string, unknown>> | undefined;
}

/**
 *
 * @param mutationClass - The class generated in growl_graphql
 * @param options - https://www.apollographql.com/docs/react/v2.6/data/mutations/#options
 * @returns {{mutate: (options?: MutationHookOptions) => Promise<ExecutionResult<any>>, data:
 *   any, called: boolean, client: ApolloClient<object>, loading: boolean, error: ApolloError}}
 */
const useMutation = (mutationClass: Mutation, options: UseMutationOptions = {}): UseMutation => {
  const { mutationResponseKey = '', successMessage = 'Update successful', mutationOptions = {} } = options;

  const [mutate, context] = useApolloMutation(mutationClass.query, mutationOptions);

  // https://www.apollographql.com/docs/react/v2.6/data/mutations/#result
  const { loading, error, data, called } = context;
  const client = context.client as ApolloClient<Record<string, unknown>> | undefined;
  /**
   * This effect handles mutation errors
   */
  useEffect(() => {
    if (error) {
      // Report the error to Sentry
      reportError(error);

      // Report the error to MaypleAnalytics, so it would get to all tracking systems
      reportEvent('error', 'caught', error?.message, { ...error });

      // Extract the errors from error object.
      const graphQLErrors = error?.graphQLErrors || [];
      // @ts-ignore
      const networkErrors = error?.networkError?.result?.errors || [];
      // First try to show the network errors if exists. usually http 400 code
      if (networkErrors.length > 0) {
        networkErrors.forEach((nwErr: Error) => {
          setNotification(JSON.stringify(nwErr), 'error');
        });
        // Then try to show the graphql errors. usually http 500 code
      } else if (graphQLErrors.length > 0) {
        graphQLErrors.forEach((gqlErr) => {
          setNotification(JSON.stringify(gqlErr), 'error');
        });
      }
      // Show the general error message.
      if (error.message) {
        setNotification(JSON.stringify(error.message), 'error');
      }
    }
  }, [error]);
  /**
   * This effect handles mutation responses
   */
  useEffect(() => {
    if (data) {
      const mutationKey = mutationResponseKey || mutationClass.name.replace('Mutation', '');
      const response = data?.[mutationKey] || {};
      const isEntityOperationResponse = response?.result != null && response?.success != null;

      if (isEntityOperationResponse) {
        setEntityOperationResponseNotification(response);
      } else if (successMessage !== '') {
        setNotification(successMessage, 'success');
      }
    }
  }, [data, mutationClass.name, successMessage, mutationResponseKey]);

  //
  return {
    mutate,
    loading,
    error,
    data,
    called,
    client,
  };
};

export default useMutation;
