import isURL from 'validator/lib/isURL';

import { SetClientMetadata } from '../useClientMetadata';
import { handleClientError } from '../../services/logger';

// eslint-disable-next-line no-shadow
export enum RemoteControlActionType {
  REFRESH_BROWSER = 'REFRESH_BROWSER',
  REDIRECT_WINDOW = 'REDIRECT_WINDOW',
}

export type RemoteControllerData = Record<RemoteControlActionType, any>;
type GetControllerReturn = {
  controller: (() => any) | null;
  remoteControllerUpdate: RemoteControllerData;
};

const refreshUserBrowser = () => {
  window.location.reload();
};

const redirectCurrentTabTo = (url: string): boolean => {
  if (isURL(url)) {
    window.location.href = url;
    return true;
  }

  return false;
};

const getController = (remoteController: RemoteControllerData): GetControllerReturn => {
  const remoteControllerUpdate = { ...remoteController };
  let controller = null;

  if (remoteController[RemoteControlActionType.REFRESH_BROWSER]) {
    remoteControllerUpdate.REFRESH_BROWSER = false;
    controller = refreshUserBrowser;
  } else if (remoteController[RemoteControlActionType.REDIRECT_WINDOW]) {
    remoteControllerUpdate.REDIRECT_WINDOW = '';
    controller = () => {
      redirectCurrentTabTo(remoteController[RemoteControlActionType.REDIRECT_WINDOW] as string);
    };
  }

  return {
    controller,
    remoteControllerUpdate,
  };
};

// eslint-disable-next-line import/prefer-default-export
export const handleRemoteControlAction = async (
  remoteController: RemoteControllerData,
  saveClientMetadata: SetClientMetadata,
): Promise<void> => {
  try {
    const { controller, remoteControllerUpdate } = getController(remoteController);

    if (controller) {
      await saveClientMetadata({ remoteController: remoteControllerUpdate });
      controller();
    }
  } catch (e) {
    handleClientError(e);
  }
};
