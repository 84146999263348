import axios from 'axios';

import { config } from '../../config';
import { handleClientError } from '../../../fe_common/client/services/logger/index';

export async function fetchData(url) {
  return axios({
    url,
    timeout: 20000,
    method: 'get',
    responseType: 'json',
  });
}

export async function getCpanelServerVersion() {
  try {
    const response = await fetchData(`${config.serverBaseUrl}/cpanel/version`);
    return response.data;
  } catch (err) {
    handleClientError(err, 'Failed getting Cpanel version.');
    return null;
  }
}
