import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import { TextDisplay } from '../../display';
import { StatusIndicator } from '../StatusIndicator';
import { COMPANY_PROJECT_LIFE_CYCLE_PAIRS, TARGET_KPI_LABELS_LOOKUP } from '../../../app/enums';

const styles = () => ({
  root: {
    backgroundColor: '#F4F8F9',
    margin: '16px 0',
    alignContent: 'center',
    minHeight: 60,
    borderRadius: '16px 0 16px 16px',
  },
});

function ProjectsListItemWrapped(props) {
  const { project, classes } = props;
  const {
    id,
    name,
    projectLifeCycleStatus,
    projectDefinition: { primaryObjectives },
  } = project;
  const { targetKPI } = primaryObjectives;

  const component = (
    <Grid container justifyContent="center" spacing={2} className={classes.root}>
      <Grid item xs={7} sm={3}>
        <TextDisplay>{name}</TextDisplay>
      </Grid>
      <Grid item xs={5} sm={3}>
        <StatusIndicator status={projectLifeCycleStatus} options={COMPANY_PROJECT_LIFE_CYCLE_PAIRS} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextDisplay>{TARGET_KPI_LABELS_LOOKUP[targetKPI]}</TextDisplay>
      </Grid>
    </Grid>
  );

  // don't allow clicking on finished projects
  if (projectLifeCycleStatus === 'FINISHED') {
    return <div>{component}</div>;
  } else {
    return (
      <Link to={`/company/projects/${id}`} style={{ textDecoration: 'none' }}>
        {component}
      </Link>
    );
  }
}

ProjectsListItemWrapped.propTypes = {
  project: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export const ProjectsListItem = withStyles(styles)(ProjectsListItemWrapped);
