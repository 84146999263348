import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/react';

import { config } from './config';
import Root from './components/Root';

import { Environment } from '../fe_common/client/app/types';

import 'react-table-6/react-table.css';

// This css file is overriding react-table.css definitions, so it needs to be loaded right after
import '../fe_common/client/app/rt-override.css';

import './style.css';

// In the past we used hash router, so the link route started with "/#/"
// To have backward compatibility, We check for old links and reload the page with the correct route structure.
if (window && window.location.href && window.location.hash && window.location.href.includes('/#/')) {
  window.location.href = window.location.href.replace('/#/', '/');
}

if (config.enableSentry) {
  const sentryIntegrations = [Sentry.browserTracingIntegration()];

  const options = {
    dsn: config.sentryClientId,
    environment: config.env,
    debug: config.env === Environment.STORKY || config.env === Environment.TORKY || config.env === Environment.DORKY,
    integrations: sentryIntegrations,
    tracesSampleRate: 0.25,
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    ignoreUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /sandbox\.bluesnap\.com\/web-sdk\/4\/bluesnap\.js/i,
    ],
  };

  Sentry.init(options);
}

render(<Root />, window.document.querySelector('#root'));
