import React, { FC } from 'react';
import MuiSkeleton, { SkeletonProps } from '@material-ui/lab/Skeleton';

interface SkeletonContainerProps extends SkeletonProps {
  loading: boolean;
}

const SkeletonContainer: FC<SkeletonContainerProps> = (props) => {
  const { children, loading, ...rest } = props;

  if (loading) {
    return <MuiSkeleton {...rest} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default SkeletonContainer;
